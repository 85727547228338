import React, { useEffect, useState } from "react";
import styles from "../Styles/Dashboard.module.css";
import Schedule_tbl from "../Components/Schedule_tbl";
import { useNavigate } from "react-router-dom";
import ServicesPlans from "../Components/ServicesPlans";
import MenuAppBar from "../Components/Header";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import Drawer_customer from "../Components/Drawer";
import Add_query from "../Modals/Add_query";
import loader_img from "../Media/Business_chart.gif"
import Footer from "../Components/footer";

function Dashboard({ base_url }) {
  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let User_mode = storage.getItem("User_mode");
  let profile = JSON?.parse(storage.getItem("profile"));
  let support_details = JSON?.parse(storage.getItem("support_details"));

  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let customer_name = storage.getItem("customer_name");
  const [schedule_list, setSchedule_List] = useState([]);
  const navigate = useNavigate();
  const [dashboard_data, setDashboard_Data] = useState([]);
  const [schedule_heat_map_data, setSchedule_Heat_Map_Data] = useState([]);


  const [range_flag, setRange_Flag] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [params, setParams] = useState("");
  const [display2, setDisplay2] = useState(false);

  // load usestates

  const [schedule_load,setSchedule_Load] = useState(false)
  const [count_load,setCount_Load] = useState(false)
  const [heat_map_load,setHeat_Map_Load] = useState(false)


  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const [year, setYear] = useState(info_arr[3]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigate = (val) => {
    if (val == "/sites" || val == "/service_avail") {
      if (val == "/service_avail") {
        storage.setItem("site_id", "");
      }
      navigate(val);
    } else {
      storage.setItem("flag_page", val);
      navigate("/services");
    }
  };

  const get_schedule_list = (temp_param) => {
    setSchedule_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    if (site_id != "" && site_id != null) {
      formData.append("site_id", site_id);
    }
    if (temp_param != "custom") {
      formData.append("scheduled_date", temp_param);
    }
    if (temp_param == "custom") {
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
    }

    axios({
      url: `${base_url}/customer-dashboard-scheduled-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setSchedule_Load(false)
        if (res?.data?.code == "200") {
          setSchedule_List(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            text: res?.data?.message,
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
        }
      })
      .catch((err) => {
        setSchedule_Load(false)
        
      });
  };

  const get_dashboard_counts = async () => {
    setCount_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("customer_id", customer_id);
    if (site_id != "" && site_id != null) {
      formData.append("site_id", site_id);
    }
    axios({
      url: `${base_url}/customer-dashboard-count`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setCount_Load(false)
        if (res?.data?.code == "200") {
          setDashboard_Data(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            text: res?.data?.message,
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
        }
      })
      .catch((err) => {
        setCount_Load(false)
        
      });
  };
  const get_schedule_heat_map = async () => {
    setHeat_Map_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    if (site_id != "" && site_id != null) {
      formData.append("site_id", site_id);
    }
    formData.append("year", Number(year));
    // formData.append("year", year == ""?2024:Number(year));
    axios({
      url: `${base_url}/customer-scheduled-heatmap`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setHeat_Map_Load(false)
        if (res?.data?.code == "200") {
          setSchedule_Heat_Map_Data(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            text: res?.data?.message,
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
        }
      })
      .catch((err) => {
        setHeat_Map_Load(false)
        
      });
  };



  useEffect(() => {
    if (token != "" && token != null) {
      if(User_mode == "customer"){
      storage.setItem("site_id","")
      }
      get_schedule_list(params);
      get_dashboard_counts();
    }
  }, []);

  useEffect(() => {
    if (token != "" && token != null) {
      get_schedule_heat_map();
    }
  }, [year]);

  const handleYear = (e) => {
    if (e.target.value != "") {
      setYear(e.target.value);
    }
  };

  const handleDateRange_schedule = (e) => {
    setParams(e.target.value);
    if (e.target.value == "custom") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list(e.target.value);
    }
  };

  const handleDateChange_schedule = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      setDisplay2(true);
      // setShowBtn(true);
    } else {
      setParams("");
      setRange_Flag(false);
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list("");

      // dispatch(
      //   get_client_type_sample_revenue_data(
      //     setRevenue_Load,
      //     department,
      //     "today",
      //     start_date,
      //     end_date,
      //     setSample_Revenue_Data
      //   )
      // );
      setDisplay2(false);
    }
  };

  const handle_click_schedule = () => {
    if (selectedFromDate == undefined && selectedToDate == undefined) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        text: "Please Select Date",
        customClass: {
          htmlContainer: 'custom-html-container',
        },
      });
    } else {
      get_schedule_list(params);
      // dispatch(
      //   get_client_type_sample_revenue_data(
      //     setRevenue_Load,
      //     department,
      //     params,
      //     start_date,
      //     end_date,
      //     setSample_Revenue_Data
      //   )
      // );
    }
  };

  const handleSites = (e) => {
    storage.setItem("site_id", "");
    navigate("");
  };

  const handleRedirect = (e, path) => {
    if (e.ctrlKey) {
      // if (path == "/service_avail") {
      //   storage.setItem("site_id", "");
      // }
      if(User_mode == "customer"){
        storage.setItem("site_id", "");
      }
      window.open(path, "_blank");
    } else {
      // if (path == "/service_avail") {
      //   storage.setItem("site_id", "");
      // }
      if(User_mode == "customer"){
        storage.setItem("site_id", "");
      }
      navigate(path);
    }
  };

  return (
    <>
      <Drawer_customer />
      {/* <MenuAppBar header_name ={customer_name}/> */}
      {(schedule_load || heat_map_load || count_load)?<img id={styles.loader} src={loader_img}/>:   <div
        className="container-fluid"
        style={{ paddingLeft: "5em", paddingTop: "0em", paddingRight: "1em" }}
      >
        <div className="row">
          {/* <p>{moment('22-12-2014 10:50:00')?.format("h:mm:ss a")}</p> */}
          <div className="col-sm-12 col-md-12" id={styles.count_Container}>
            <div
              // onClick={() => handleNavigate("/sites")}
              onClick={(e) => handleRedirect(e, "/sites")}
            >
              <span>
                {dashboard_data[0]?.site_count == null
                  ? 0
                  : dashboard_data[0]?.site_count}
              </span>
              <p># Sites</p>
            </div>
            <div
              onClick={(e) => handleRedirect(e, "/purchase_order")}
              //  onClick={() => handleNavigate("/purchase_order")}
            >
              <span>
                {dashboard_data[0]?.order_count == null
                  ? 0
                  : dashboard_data[0]?.order_count}
              </span>
              <p># Purchase Order</p>
            </div>
            <div
              onClick={(e) => handleRedirect(e, "/service_avail")}
            >
              <span>
                {dashboard_data[0]?.service_count == null
                  ? 0
                  : dashboard_data[0]?.service_count}
              </span>
              <p># Services</p>
            </div>
            <div
              onClick={(e) => handleRedirect(e, "/invoices")}

            >
              <span>
                {dashboard_data[0]?.invoice_count == null
                  ? 0
                  : dashboard_data[0]?.invoice_count}
              </span>
              <p># Invoices</p>
            </div>
            <div
              onClick={(e) => handleRedirect(e, "/invoices")}
              //  onClick={() => handleNavigate("/invoices")}
            >
              <span>
                {dashboard_data[0]?.invoice_amount == null
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      dashboard_data[0]?.invoice_amount
                    )}
              </span>
              <p>Total Invoice Amount</p>
            </div>
            <div
              onClick={(e) => handleRedirect(e, "/invoices")}
              // onClick={() => handleNavigate("/invoices")}
            >
              <span style={{ color: (dashboard_data[0]?.outstanding_amount == null || Number(dashboard_data[0]?.outstanding_amount)<0 || Number(dashboard_data[0]?.outstanding_amount) == 0) ?"green":"red" }}>
                {(dashboard_data[0]?.outstanding_amount == null || Number(dashboard_data[0]?.outstanding_amount)<0)
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      dashboard_data[0]?.outstanding_amount
                    )}
              </span>
              <p>Outstanding</p>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-3">
            <div id={styles.btn_container}>
              <button onClick={handleOpen} className="btn">Raise Query</button>
            </div>
          </div> */}
        </div>

        <div className="row mt-4">
          <div className="col-sm-12 col-md-6">
           <Schedule_tbl
              handleDateRange={handleDateRange_schedule}
              range_flag={range_flag}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              handleDateChange={handleDateChange_schedule}
              data={schedule_list}
              handleClick={handle_click_schedule}
              display2={display2}
              params_schedule={params}
              base_url={base_url}
            />
          </div>
          <div className="col-sm-12 col-md-6" id={styles.map_contain}>
            <div id={styles.status_contain}>
              {/* <div>
                <div style={{backgroundColor:"#007cc3"}}></div> <p>Planned</p>
             </div>
             <div>
             <div style={{backgroundColor:"#007CC31A"}}></div> <p>Scheduled</p>
             </div> */}
              <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                <div style={{ backgroundColor: "#007cc3" }}></div>{" "}
                <p>Scheduled</p>
              </div>

              <div
                style={{ marginLeft: "70%", height: "40px", width: "100px" }}
              >
                <select
                  onChange={handleYear}
                  value={year}
                  className="form-select"
                  name=""
                  id=""
                >
                  <option value="" selected>
                    Select Year
                  </option>
                  <option value={Number(info_arr[3]) - 1}>
                    {Number(info_arr[3]) - 1}
                  </option>
                  <option value={info_arr[3]}>{info_arr[3]}</option>
                  <option value={Number(info_arr[3]) + 1}>
                    {Number(info_arr[3]) + 1}
                  </option>
                </select>
              </div>
            </div>
            <div className="mt-5" id={styles.heat_map}>
              <ServicesPlans service_data={schedule_heat_map_data} base_url={base_url} />
            </div>
          </div>
        </div>
      </div>}


      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                    <p id={styles.copyright}>
                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}

<Footer />


    </>
  );
}

export default Dashboard;
