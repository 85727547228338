import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "../Styles/Modals.module.css";
import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 5,
};

function Add_query({ open, handleOpen, handleClose,query_check,base_url}) {
 
  const storage = window.sessionStorage;
  const profile = JSON.parse(storage.getItem("profile"));
  const customer_id = storage?.getItem("customer_id");
  const token = storage.getItem("token");
  const [query_list, setQuery_List] = useState([]);
  const [query_type,setQuery_Type] = useState("1")

  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [desc, setDesc] = useState("");
  const [query_file, setQuery_File] = useState({});
  const [btn_disable, setBtn_Disable] = useState(false);

  const handleFile = (e) => {
    setQuery_File(e.target.files[0]);
  };

  const get_query = () => {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("issue_type", query_type);
    axios
      .post(
        `${base_url}/query-type`,
        formData,
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "Multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setQuery_List(res.data?.data);

      })
      .catch((err) => {
        
      });
  };

  const post_query = async () => {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("title", title);
    formData.append("customer_id", customer_id);
    formData.append("query_type_id", query);
    formData.append("description", desc);
    formData.append("upload_file", query_file);

    axios
      .post(
        `${base_url}/query-save-ajax`,
        formData,
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "Multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        handleClose();
        setDesc("");
        setTitle("");
        setQuery("");
        setBtn_Disable(false);
        setQuery_File({});
        if (
          query_check == "true"
        ) {
          window.location.reload();
        }
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          text: "Query Added Successfully",
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    if(token !="" && token != null){
      get_query();
    }

  }, [query_type]);

  const handleType = (event)=>{
    setQuery_Type(event.target.value);
  }

  const handleSubmit = (e) => {
    setBtn_Disable(true);
    e.preventDefault();
    post_query();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id={styles.query_popup}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Raise Ticket
              </h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button> */}
              <CloseIcon id={styles.close_icon} onClick={handleClose} />
            </div>
            <div className="modal-body" id={styles.query_modal}>
              <form onSubmit={handleSubmit}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={query_type}
                  onChange={handleType}
                >
                  {/* <div className='col-md-4'> */}
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Query"
                  />
                  {/* </div> */}
                  {/* <div className='col-md-4'> */}
                  <FormControlLabel
                   value="0"
                    control={<Radio />}
                    label="Escalation"
                  />
                  {/* </div> */}
                </RadioGroup>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="" id={styles.query_label}>
                      Title<span>*</span>:{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control mt-1"
                      style={{ height: "33px" }}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="" id={styles.query_label}>
                      Query Type<span>*</span>:{" "}
                    </label>
                    <select
                      name=""
                      id={styles.query_options}
                      className="form-control mt-1"
                      onChange={(e) => setQuery(e.target.value)}
                      required
                    >
                      <option value="" selected>
                        Select Type
                      </option>
                      {query_list?.map((ele) => (
                        <option value={ele.id}>{ele.query_type}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" id={styles.query_label}>
                      Description<span>*</span>
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="form-control mt-1"
                      onChange={(e) => setDesc(e.target.value)}
                      style={{ resize: "none" }}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="" id={styles.UploadFile_container}>
                    <label htmlFor="" id={styles.query_label}>
                      Upload File:{" "}
                    </label>
                    <input
                      type="file"
                      style={{ fontSize: "12px" }}
                      className="form-control mt-1"
                      onChange={handleFile}
                    />
                  </div>
                </div>
                <div className="row" id={styles.submit_btn_container}>
                  <button
                    type="submit"
                    className="btn btn-primary col-3"
                    disabled={btn_disable ? true : false}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Add_query;
