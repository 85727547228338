import React, { useEffect, useState } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Iris_footer from "../Components/Iris_footer";

function AccountsList({ base_url }) {
  const navigate = useNavigate();
  const storage = window.sessionStorage;
  const token = storage.getItem("token");
  const profile = JSON.parse(storage.getItem("profile"));
  const number = storage.getItem("login_mobile_no");
  const customer = JSON.parse(storage?.getItem("customer"));
  const count = storage.getItem("count");

  const [sampling_client_list, setSampling_Client_List] = useState([]);
  const [audit_client_list, setAudit_Client_List] = useState([]);

  const [client_list, setClient_List] = useState([]);

  const [load, setLoad] = useState(false);
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const get_accounts = async () => {
    setLoad(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("login_mobile_no", `${number}`);
    axios
      .post(`${base_url}/get-customer-list`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setLoad(false);
        // setAccData(res?.data?.customer);
        storage.setItem("count", res?.data?.customer?.length);
        // let sampling_arr = [];
        // let audit_arr = [];
        // for (let i = 0; i < res?.data?.customer?.length; i++) {
        //   if (res.data.customer[i]?.is_sampling != "1") {
        //     sampling_arr.push(res.data.customer[i]);
        //   }
        //   if (res.data.customer[i]?.is_audit == "1") {
        //     audit_arr?.push(res.data.customer[i]);
        //   }
        // }
        setClient_List(res.data.customer);
        // setSampling_Client_List(sampling_arr);
        // setAudit_Client_List(audit_arr);
      })
      .catch((err) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "Something went wrong.",
          customClass: {
            htmlContainer: "custom-html-container",
          },
        });
      });
  };

  const get_client_details = async (iris_id,User_mode) => {
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${User_mode == "site"?"1":"0"}`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  User_mode == "site"
                  ? "2"
                  : "0"
        })
      })
      let data = await res.json();
      storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
      storage.setItem("IS_CAPA_APPLICABLE",data?.data[0]?.IS_CAPA_APPLICABLE)
    } catch (error) {
    }
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_accounts();
    }
  }, []);

  useEffect(() => {
    if (token == "" || token == null) {
      navigate("/");
    }
  }, [storage]);

  const handleClick_Sampling = (ele) => {
    storage.setItem("customer_id", ele?.customer_id);
    storage.setItem("customer_name", ele?.capital_customer_name);
    storage.setItem("is_sampling", ele?.is_sampling);
    storage.setItem("is_audit", ele?.is_audit);
    storage.setItem("iris_id", ele?.master_client_id);
    storage.setItem("dashboard_type", "HUB");
    navigate("/dashboard");
  };
  const handleClick = (ele) => {
    storage.setItem("customer_id",ele?.customer_id)
    storage.setItem("customer_name",ele?.capital_customer_name)
    storage.setItem("is_sampling",ele?.is_sampling)
    storage.setItem("is_audit",ele?.is_audit)
    storage.setItem("iris_id",ele?.master_client_id)
    storage.setItem("master_iris_client_id",ele?.master_client_id)
    // if(ele?.master_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){   //vangoo id
    // hitting api only when audit access is given
    if(ele?.is_audit == "1"){
      get_client_details(ele?.master_client_id,"customer");
    }

    // }
    if(ele?.is_sampling != "1"){
      navigate("/dashboard")
      storage.setItem("dashboard_type","HUB")
    }else{
      storage.setItem("dashboard_type","IRIS")
      if(ele?.master_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347"){     //compass id
        navigate("/compass_dashboard")
      }else{
      navigate("/schedule_list")
      }
    }

  }

  const handleClick_Audits = (ele) => {
    storage.setItem("customer_id", ele?.customer_id);
    storage.setItem("customer_name", ele?.capital_customer_name);
    storage.setItem("iris_id", ele?.master_client_id);
    storage.setItem("is_sampling", ele?.is_sampling);
    storage.setItem("is_audit", ele?.is_audit);
    storage.setItem("iris_name", ele?.iris_site_name);
    storage.setItem("dashboard_type", "IRIS");
    if (ele?.master_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347") {
      navigate("/compass_dashboard");
    } else {
      navigate("/schedule_list");
    }
  };

  return (
    <>
      <div className="container-fluid p-4">
        {/* <div 
    // id={styles.main_header} 
    className="container-fluid mt-2">
        <div
        //  id={styles.iconContain}
          className="col-md-5">
          <i
            className="fa-solid fa-user"
            // id={styles.user}
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
            // onClick={handleOpen2}
          ></i>
          <i
            className="fa-solid fa-gear"
            // id={styles.setting}
            // onClick={() => swal("Please Select Account!!")}
          ></i>
          <Tooltip title="Sign Out" placement="bottom">
            <div 
            // id={styles.logout_btn} onClick={handleLogout}
            >
              <PowerSettingsNewIcon sx={{ height: "20px" }} />
            </div>
          </Tooltip>
        </div> 
         </div> */}

        <div className="row">
          <div className={`${styles.equinoxLogo2} col-md-6 col-sm-12`}>
            <img src={equinoxLogo} alt="" />
          </div>

          <div className={`${styles.customerhubLogo} col-md-6 col-sm-12`}>
            <img src={hubcslogo} alt="" />
          </div>

          {/* <div
            className={`${styles.equinoxLogo} col-md-6 col-sm-12`}
            style={{ justifyContent: "flex-end", color: "#44546A" }}
          >
            <h4>Customer HUB</h4>
          </div> */}
        </div>
        <div style={{ marginBottom: "5vh" }}>
          <h3 style={{ color: "#007cc3" }}>
            Welcome !!
            {/* Welcome {profile?.capital_customer_name} */}
          </h3>
        </div>

        {load ? (
          // <Account_load />
          <>LOADING....</>
        ) : (
          <>
            {" "}
            {client_list?.length == 0 ? (
              <h6 id={styles.site_header} className="h6 mt-5">
                There is no Account registered on your number
              </h6>
            ) : client_list?.length == 1 ? (
              <h6 id={styles.site_header} className="h6 mt-5">
                There is {client_list?.length} Account
                registered on your number, Please Select an Account to process
                further.
              </h6>
            ) : (
              <h6 id={styles.site_header} className="h6 mt-5">
                There are {client_list?.length} Accounts
                registered on your number, Please Select an Account to process
                further.
              </h6>
            )}
            {client_list?.length == 0 ? (
              <></>
            ) : (
              <div id={styles.site_list_container}>
                <div className="col-md-6">
                  <div
                    className="col-md-12"
                    style={{ maxHeight: "54vh", overflow: "auto" }}
                  >
                    {client_list?.map((ele, index) => (
                      <>
                        <div id={styles.name_contain}  onClick={() => handleClick(ele)}>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "2vw",
                            }}
                            // onClick={()=>handleClick(ele)}
                           
                          >
                            <span>{ele?.capital_customer_name}</span>
                            <PlayArrowIcon />
                            {/* <span style={{fontSize:"20px"}}>&#11208;</span> */}
                          </h2>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* {audit_client_list?.length == 0 ? (
              <h6 id={styles.site_header} className="h6 mt-5">
                There is no Audit Account registered on your number
              </h6>
            ) : audit_client_list?.length == 1 ? (
              <h6 id={styles.site_header} className="h6 mt-5">
                There is {audit_client_list?.length} Audit Account registered on
                your number, Please Select an Account to process further.
              </h6>
            ) : (
              <h6 id={styles.site_header} className="h6 mt-5">
                There are {audit_client_list?.length} Audit Accounts registered
                on your number, Please Select an Account to process further.
              </h6>
            )}
            {audit_client_list?.length == 0 ? (
              <></>
            ) : (
              <div id={styles.site_list_container}>
                <div className="col-md-6">
                  <div
                    className="col-md-12"
                    style={{ maxHeight: "54vh", overflow: "auto" }}
                  >
                    {audit_client_list?.map((ele, index) => (
                      <>
                        <div id={styles.name_contain}>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "2vw",
                            }}
                            onClick={() => handleClick_Audits(ele)}
                          >
                            <span>{ele?.capital_customer_name}</span>
                            <PlayArrowIcon />
                          </h2>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )} */}
          </>
        )}
      </div>

      <Iris_footer />
    </>
  );
}

export default AccountsList;
