import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import EventNoteIcon from "@mui/icons-material/EventNote";
import styles from "../Styles/Table.module.css";
import Query_desc from "../Modals/Query_desc";
import Drawer_customer from "../Components/Drawer";
import Add_query from "../Modals/Add_query";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import ReactReadMoreReadLess from "react-read-more-read-less";
import loader_img from "../Media/Business_chart.gif"
import Footer from "../Components/footer";

function Query_list({ base_url }) {
  
  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let profile = JSON?.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let User_mode = storage.getItem("User_mode");

  const [fill_status, setFill_status] = useState("");
  const [fill_data, setFill_data] = useState([]);
  const [query, setQuery] = useState([]);
  const [desc, setDesc] = useState("");
const [individual_data,setIndividual_Data] = useState([])

let support_details = JSON?.parse(storage.getItem("support_details"));
const [year_heat_map, setYear_Heat_Map] = useState(new Date());
let info_arr = year_heat_map?.toString()?.split(" ");


const [query_load,setQuery_Load] = useState(false)

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleClick = (data) => {
    setIndividual_Data(data?.rowData)
    // setDesc(row);
    handleOpen();
  };

  const handleFilter = (val_filter) => {
    setFill_status(val_filter);

    let filter_data = query?.filter((ele) => {
      return ele.Status == val_filter;
    });
    setFill_data(filter_data);
  };

  let new_count = 0;
  let inprogress_count = 0;
  let closed_count = 0;

  query?.forEach((ele) => {
    if (ele.Status == "New") {
      new_count++;
    }
    if (ele.Status == "In-Progress") {
      inprogress_count++;
    }
    if (ele.Status == "Closed") {
      closed_count++;
    }
  });


  function getText(data) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = data;
    return divContainer.innerText || divContainer.textContent || "";
  }


  const get_query_list = () =>{
    setQuery_Load(true)
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    if (site_id != "" && site_id != null) {
      formData.append("site_id", site_id);
    }
    axios({
      url: `${base_url}/query-engine-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setQuery_Load(false)
        if (res?.data?.code == "200") {
          // setSchedule_Heat_Map_Data(res?.data?.data);
          setQuery(res?.data?.data)
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            text: res?.data?.message,
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
        }
      })
      .catch((err) => {
        setQuery_Load(false)
        
      });
  }

  useEffect(()=>{
    if(token != "" && token != null){
      if(User_mode == "customer"){
        storage.setItem("site_id","")
        }
      get_query_list();
    }
  },[])

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "200px", maxWidth: "250px" },
        }),
        customBodyRender:(row)=>{
          return(
            <ReactReadMoreReadLess
            charLimit={50}
            readMoreText={"Read more ▼"}
            readLessText={"Read less ▲"}
            readMoreClassName={styles.readMoreClassName}
            readLessClassName={styles.readLessClassName}
          >

      {getText(row)}
          </ReactReadMoreReadLess>
          )
        }
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row,data) => {
          return (
            <p id={styles.view_link} onClick={() => handleClick(data)}>
              View
            </p>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },

    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRender: (row) => {
          return (
            <button
              id={styles.status_btn}
              style={{
                // color: "#434a4f",
                color:
                row == "New"
                ? "#F273E6"
                : row == "In-Progress"
                ? "#F1C93B"
                : row == "Closed"
                ? "#A8DF8E"
                : "grey",
                border:
                row == "New"
                    ? "2px solid #F273E6"
                    : row == "In-Progress"
                    ? "2px solid #F1C93B"
                    : row == "Closed"
                    ? "2px solid #A8DF8E"
                    : "grey",
                padding: "5px",
                margin: "5px",
                fontSize: "14px",
                backgroundColor:"white",

              }}
            >
              {row}
            </button>

            // <button
            //   id={styles.status_btn}
            //   style={{
            //     color: "#434a4f",
            //     padding: "5px 7px",
            //     fontSize: "12px",
            //     backgroundColor:
            //       row == "New"
            //         ? "#F273E6"
            //         : row == "In-Progress"
            //         ? "#F1C93B"
            //         : row == "Closed"
            //         ? "#A8DF8E"
            //         : "grey",
            //         height:"25px"
            //   }}
            // >
            //   {row}
            // </button>
          );
        },
      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "150px" },
        }),
        customBodyRender: (row, data) => {
          if (data.rowData[3] != "Closed") {
            return <></>;
          }
          return    (    
                     <ReactReadMoreReadLess
                charLimit={50}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName={styles.readMoreClassName}
                readLessClassName={styles.readLessClassName}
              >

          {getText(row)}
              </ReactReadMoreReadLess>
          )
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      <Drawer_customer query_check={"true"} />
      {query_load?<div><img id={styles.loader} src={loader_img}/></div>:<div
        className="container-fluid"
        style={{ paddingLeft: "5em", paddingRight: "1em" }}
      >
        {/* <div className="row" id={styles.status_contain}>
          <div
            className="col-md-1 col-sm-6"
            onClick={() => handleFilter("")}
            style={{ backgroundColor: "#89BBE0", color: "#434a4f" }}
          >
            All : {query.length}
          </div>
          <div
            className="col-md-1 col-sm-6"
            onClick={() => handleFilter("New")}
            style={{ backgroundColor: "#F273E6", color: "#434a4f" }}
          >
            New : {new_count}
          </div>
          <div
            className="col-md-1 col-sm-6"
            style={{ backgroundColor: "#F1C93B", color: "#434a4f" }}
            onClick={() => handleFilter("In-Progress")}
          >
            In Progress: {inprogress_count}
          </div>
          <div
            className="col-md-1 col-sm-6"
            style={{ backgroundColor: "#A8DF8E", color: "#434a4f" }}
            onClick={() => handleFilter("Closed")}
          >
            Closed: {closed_count}
          </div>
        </div> */}

<div className="row" id={styles.status_contain}>
          <div
            className="col-md-1 col-sm-6"
            onClick={() => handleFilter("")}
            style={{ backgroundColor: "white", color: "#89BBE0", border:"2px solid #89BBE0" }}
          >
            All : {query.length}
          </div>
          <div
            className="col-md-1 col-sm-6"
            onClick={() => handleFilter("New")}
            style={{ backgroundColor: "white", color: "#F273E6", border:"2px solid #F273E6" }}
          >
            New : {new_count}
          </div>
          <div
            className="col-md-1 col-sm-6"
            style={{ backgroundColor: "white", color: "#F1C93B ", border:"2px solid #F1C93B" }}
            onClick={() => handleFilter("In-Progress")}
          >
            In Progress: {inprogress_count}
          </div>
          <div
            className="col-md-1 col-sm-6"
            style={{ backgroundColor: "white", color: "#A8DF8E", border:"2px solid #A8DF8E" }}
            onClick={() => handleFilter("Closed")}
          >
            Closed: {closed_count}
          </div>
        </div>
        <div className="row">
          <MUIDataTable
            title={
              <h4
                id={styles.list_head}
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                <EventNoteIcon
                  sx={{
                    marginRight: "10px",
                    fontSize: "27px",
                    marginTop: "-2px",
                    color: "#007cc3",
                  }}
                />
                Query List
              </h4>
            }
            data={fill_status == "" ? query : fill_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              responsive: "standard",
              viewColumns: false,
              print: false,
              download: false,
              filter: false,
              tableBodyMaxHeight: "42vh",
              rowsPerPageOptions: [10, 25, 50, 100],
              sortOrder: {
                name: "created_at",
                direction: "desc",
               },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
{/* <button onClick={handleOpen2}>check</button> */}
      </div>}
      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
        
                    <p id={styles.copyright}>
        

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
    
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}

<Footer/>

      <Query_desc
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        data={individual_data}
      />



    </>
  );
}

export default Query_list;
