import React, { useEffect, useState, useRef } from "react";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Audit.module.css";
import no_data_icon from "../Media/no_data_icon2.png";
import { DateRangePicker } from "rsuite";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Section_data_table from "../Components/Section_data_table";
import loader_img from "../Media/Business_chart.gif";
import Site_record_line_chart from "../Components/Site_record_line_chart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Iris_footer from "../Components/Iris_footer";

function Audit_Section_Dashboard({ base_url, compass_flag }) {
  const storage = window.sessionStorage;
  let mobile_no = storage.getItem("login_mobile_no");
  let token = storage.getItem("token");

  let iris_id = storage.getItem("iris_id");
  let User_mode = storage.getItem("User_mode");

  let is_sampling = storage.getItem("is_sampling");
  let is_audit = storage.getItem("is_audit");
  let site_type = storage.getItem("site_type");

  const [site_load, setsite_Load] = useState(false);
  const [site_list, setSite_List] = useState([]);
  const [selected_site, setSelected_Site] = useState("");
  const [graph_site, setGraph_Site] = useState("");

  const [client_details, setClient_Details] = useState([]);
  const [client_load, setClient_Load] = useState(false);

  const [month_wise_load, setMonth_wise_Load] = useState(false);
  const [month_wise_data, setMonth_Wise_Data] = useState([]);

  const [section_data, setSection_Data] = useState([]);
  const [header_data, setHeader_Data] = useState([]);

  const [section_load, setSection_Load] = useState(false);

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [selectedFromDate, setSelectedFromDate] = useState(firstDay);
  const [selectedToDate, setSelectedToDate] = useState(lastDay);

  let format2 = moment().format("MM/YYYY");
  const [month_filter, setMonth_filter] = useState(new Date());
  const [month, setMonth] = useState(Number(format2?.split("/")[0]));
  const [year, setYear] = useState(Number(format2?.split("/")[1]));

  const [graph_show, setGraph_Show] = useState(false);

  const get_client_details = async () => {
    setClient_Load(true);

    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
    let data = await res.json();
    setClient_Load(false);
    if (data?.response.CODE == "200") {
      setClient_Details(data?.data);
    } else {
      setClient_Details([]);
    }
  } catch (error) {
    setClient_Load(false);
    setClient_Details([]);
  }

    // try {
    //   let res = await fetch(
    //     `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${
    //       site_type == "all"
    //         ? "2"
    //         : User_mode == "site"
    //         ? "1"
    //         : "0"
    //     }`
    //   );
    //   let data = await res.json();
    //   setClient_Load(false);
    //   setClient_Details(data?.data);
    // } catch (error) {
    //   setClient_Load(false);
    // }
  };

  const get_month_wise_score = async (start_date, end_date, val,check_single) => {
    setMonth_wise_Load(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    setMonth_Wise_Data([]);
    // try {
    //   let res = await fetch(
    //     `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_MONTH_WISE_SITES_SCORE_V2/${newStartDate}/${newEndDate}/${val}/${
    //       check_single == "single"?"1":  site_type == "all"
    //         ? "2"
    //         : User_mode == "site"
    //         ? "1"
    //         : "0"
    //     }`
    //   );
    //   let data = await res.json();
    //   setMonth_wise_Load(false);
    //   if (data?.response?.CODE == "200") {
    //     let arr = [];
    //     let date_arr = [];
    //     arr.push({
    //       PERCENTAGE: 0,
    //       SITES_COUNT: "1",
    //       MONTH: "",
    //     });
    //     for (let i = 0; i < data?.data?.length; i++) {
    //       arr.push(data?.data[i]);
    //       date_arr.push(data?.data[i]?.MONTH);
    //     }

    //     setMonth_Wise_Data(arr);
    //   } else {
    //     setMonth_Wise_Data([]);
    //   }
    // } catch (error) {
    //   setMonth_wise_Load(false);
    //   setMonth_Wise_Data([]);
    // }

    try {
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_MONTH_WISE_SITES_SCORE_V4`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          StartDate:newStartDate,
          EndDate:newEndDate,
          ID:val,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
       let data = await res.json();
      setMonth_wise_Load(false);
      if (data?.response?.CODE == "200") {
        let arr = [];
        let date_arr = [];
        arr.push({
          PERCENTAGE: 0,
          SITES_COUNT: "1",
          MONTH: "",
        });
        for (let i = 0; i < data?.data?.length; i++) {
          arr.push(data?.data[i]);
          date_arr.push(data?.data[i]?.MONTH);
        }

        setMonth_Wise_Data(arr);
      } else {
        setMonth_Wise_Data([]);
      }
    } catch (error) {
      setMonth_wise_Load(false);
      setMonth_Wise_Data([]);
    }
  };

  const get_section_wise_score = async (start_date, end_date, val) => {
    setSection_Data([]);
    setSection_Load(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_SECTION_WISE_SCORE_TABLE_V1/${newStartDate}/${newEndDate}/${val}/${
      //     site_type == "all"
      //       ? "2"
      //       : User_mode == "site"
      //       ? "1"
      //       : "0"
      //   }`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_SECTION_WISE_SCORE_TABLE_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          StartDate:newStartDate,
          EndDate:newEndDate,
          ID:val,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
      let data = await res.json();
      setSection_Load(false);
      if (data?.response?.CODE == "200") {
        setSection_Data(JSON.parse(data?.data));
        let temp = JSON.parse(data?.data);
        let headerData = Object.keys(temp[0]);
        setHeader_Data(headerData);
        //   }
      } else {
        setSection_Data([]);
        setHeader_Data([]);
      }
    } catch (error) {
      setSection_Load(false);
    }
  };

  const get_sites_list = async (start_date, end_date) => {
    setsite_Load(true);
    setSite_List([]);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_ALL_RECORDS_SITES_V1/${newStartDate}/${newEndDate}/${iris_id}/${
      //     site_type == "all"
      //       ? "2"
      //       : User_mode == "site"
      //       ? "1"
      //       : "0"
      //   }`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_ALL_RECORDS_SITES_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          StartDate:newStartDate,
          EndDate:newEndDate,
          ID:iris_id,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
      let data = await res.json();
      setsite_Load(false);
      if (data?.response?.CODE == "200") {
        if (data?.data?.length == 0 || data?.data?.length == undefined) {
          setSite_List([]);
          if (compass_flag) {
            setMonth_Wise_Data([]);
          }
          if (!graph_show) {
            // setSelected_Site("");
            // setGraph_Site("")
            setSection_Data([]);
            setHeader_Data([]);
          } else {
            setMonth_Wise_Data([]);
          }
        } else {
          setSite_List(data?.data);
          if (compass_flag) {
            setGraph_Site(data?.data[0]?.SITE_GUID);
            get_month_wise_score(
              start_date,
              end_date,
              data?.data[0]?.SITE_GUID,
              "single"
            );
          }
          if (!graph_show) {
            // setSelected_Site("");
          }
          // setGraph_Site("");
        }
      } else {
        if (compass_flag) {
          setMonth_Wise_Data([]);
        }
        if (!graph_show) {
          // setSelected_Site("");
          // setGraph_Site("")
          setSection_Data([]);
          setHeader_Data([]);
        } else {
          setMonth_Wise_Data([]);
        }
        setSite_List([]);
      }
    } catch (error) {
      setsite_Load(false);
      if (!graph_show) {
        // setSelected_Site("");
      }
      // setGraph_Site("")
      setSite_List([]);
    }
  };

  const handleDateChange = (date, dateString) => {
    setSection_Data([]);
    if (date) {
      setMonth_filter(date);
      // setSend_Month_filter(moment(date.$d).format("MM/YYYY"));
      let date_info = moment(date.$d).format("MM/YYYY").split("/");
      setMonth(Number(date_info[0]));
      setYear(Number(date_info[1]));
    } else {
      setMonth_filter(new Date());
      let format2 = moment().format("MM/YYYY");
      setMonth(Number(format2?.split("/")[0]));
      setYear(Number(format2?.split("/")[1]));

      // setSend_Month_filter(format2)
    }
  };

  const handleRangeChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      if (graph_show) {
        // get_month_wise_score(new_val[0], new_val[1], graph_site);
      } else {
        setSelected_Site("");
        if (compass_flag) {
          let x = 1;
        } else {
          get_section_wise_score(new_val[0], new_val[1], iris_id);
        }
      }
    } else {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
      if (graph_show) {
        // get_month_wise_score(firstDay, lastDay, graph_site);
      } else {
        setSelected_Site("");
        if (compass_flag) {
          let x = 1;
        } else {
          get_section_wise_score(firstDay, lastDay, iris_id);
        }
      }
    }
  };

  const handleSite = (e) => {
    setSelected_Site(e.target.value);
    if (compass_flag) {
      let x = 1;
    } else {
      get_section_wise_score(selectedFromDate, selectedToDate, e.target.value);
    }
  };
  const handleSite2 = (e) => {
    setGraph_Site(e.target.value);
    get_month_wise_score(selectedFromDate, selectedToDate, e.target.value,User_mode == "customer"?"single":"");
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_client_details();
    }
  }, []);

  useEffect(() => {
    if (token != "" && token != null) {
      get_sites_list(selectedFromDate, selectedToDate);
    }
  }, [selectedFromDate]);

  useEffect(() => {
    if (compass_flag) {
      let x = 1;
    } else {
      if (token != "" && token != null) {
        get_section_wise_score(selectedFromDate, selectedToDate, iris_id);
      }
    }
  }, []);

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
  ];

  const handleBack = () => {
    setGraph_Show(false);
    if (compass_flag) {
      let x = 1;
    } else {
      get_section_wise_score(selectedFromDate, selectedToDate, selected_site);
    }
  };

  const handleShow_graph = (val) => {
    setGraph_Show(true);
    get_month_wise_score(selectedFromDate, selectedToDate, val);
  };

  return (
    <>
      {compass_flag ? <></> : <Drawer_customer />}
      <div
        className="container-fluid"
        style={{
          paddingTop: "0em",
          paddingRight: "1em",
          paddingLeft: "5em",
          marginTop: compass_flag ? "70px" : "",
        }}
      >
        <div id={styles.audit_header} className="row">
          <div className="col-4">
            <h4
              style={{
                fontSize: "25px",
                fontWeight: "400",
                textAlign: "left",
                color: "#4F4F4F",
                visibility: compass_flag ? "hidden" : "visible",
              }}
            >
              {client_details[0]?.NAME}
            </h4>
          </div>
          <div className="col-4">
            <h3
              style={{ fontSize: "32px", color: "#007cc3", fontWeight: "600" }}
            >
              Section Wise
            </h3>
          </div>
        </div>

        {site_load || month_wise_load || section_load ? (
          <></>
        ) : (
          <div className="row mt-2">
            {graph_show ? (
              <div className="col-md-2 col-sm-12">
                <button
                  type="button"
                  id={styles.bckbtn}
                  title="Back"
                  class="btn"
                  onClick={handleBack}
                  // onClick={handleBackBtn}
                >
                  <ArrowBackIcon />
                </button>
              </div>
            ) : (
              <></>
            )}
            <div className="col-md-3 col-sm-6" id="month_contain">
              <DateRangePicker
                format="dd MMM yyyy"
                ranges={predefinedRanges}
                style={{ width: 250 }}
                value={[selectedFromDate, selectedToDate]}
                onChange={handleRangeChange}
                preventOverflow
              />
            </div>
         {/* {User_mode == "site" && is_sampling != "all" && is_audit != "all"?<></>: */}
          <div className="col-md-2 col-sm-6">
              {!graph_show && compass_flag != true ? (
                <Box sx={{ minWidth: "300px" }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected_site}
                      // label="State"
                      displayEmpty
                      onChange={handleSite}
                      required
                    >
                      {site_list?.length > 0 ? (
                        <MenuItem value="">All Sites</MenuItem>
                      ) : (
                        <MenuItem value="" disabled>
                          No Data Available
                        </MenuItem>
                      )}
                      {/* <MenuItem value="" disabled selected>
                            Select Site
                          </MenuItem> */}
                      {site_list?.map((ele) => (
                        <MenuItem value={ele.SITE_GUID}>{ele.NAME}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : compass_flag ? (
                <Box sx={{ minWidth: "300px" }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={graph_site}
                      // label="State"
                      displayEmpty
                      onChange={handleSite2}
                      disabled={User_mode == "site" && is_sampling != "all" && is_audit != "all"}
                      required
                    >
                      {site_list?.length == 0 ? (
                        <MenuItem value="" disabled>
                          No Data Available
                        </MenuItem>
                      ) : (
                        <MenuItem></MenuItem>
                      )}
                      {/* <MenuItem value="" disabled selected>
                            Select Site
                          </MenuItem> */}
                      {site_list?.map((ele) => (
                        <MenuItem value={ele.SITE_GUID}>{ele.NAME}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ minWidth: "300px" }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={graph_site}
                      // label="State"
                      displayEmpty
                      onChange={handleSite2}
                      required
                    >
                      {site_list?.length == 0 ? (
                        <MenuItem value="" disabled>
                          No Data Available
                        </MenuItem>
                      ) : (
                        <MenuItem></MenuItem>
                      )}
                      {/* <MenuItem value="" disabled selected>
                      Select Site
                    </MenuItem> */}
                      {site_list?.map((ele) => (
                        <MenuItem value={ele.SITE_GUID}>{ele.NAME}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </div>
            {/* } */}

          </div>
        )}
        {compass_flag ? (
          <>
            {" "}
            {site_load || month_wise_load ? (
              <div className="row" id={styles.loader_contain}>
                <img src={loader_img} alt="" />
              </div>
            ) : !site_load &&
              !month_wise_load &&
              month_wise_data?.length == 0 ? (
              <div
                className="row mb-5"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-6">
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </div>
            ) : (
              <div className="col-12 mb-5">
                {/* <Radar_chartAudit data={radar_data} /> */}
                <Site_record_line_chart data={month_wise_data} />
              </div>
            )}
          </>
        ) : (
          <>
            {!graph_show ? (
              <>
                {section_load || site_load ? (
                  <div className="row" id={styles.loader_contain}>
                    <img src={loader_img} alt="" />
                  </div>
                ) : (
                  <>
                    {(!section_load &&
                      section_data?.length == 0 &&
                      !site_load) ||
                    section_data?.length == undefined ? (
                      <div
                        className="row"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="col-6">
                          <img
                            src={no_data_icon}
                            alt=""
                            style={{ height: "200px", width: "200px" }}
                          />
                          <h4>No Data Available</h4>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row"
                        style={{
                          marginTop: "0vw",
                          marginBottom: compass_flag ? "50px" : "",
                        }}
                      >
                        <Section_data_table
                          data={section_data}
                          header_data={header_data}
                          handleShow_graph={handleShow_graph}
                          setGraph_Site={setGraph_Site}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="row">
                  {site_load || month_wise_load ? (
                    <div className="row" id={styles.loader_contain}>
                      <img src={loader_img} alt="" />
                    </div>
                  ) : !site_load &&
                    !month_wise_load &&
                    month_wise_data?.length == 0 ? (
                    <div
                      className="row"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="col-6">
                        <img
                          src={no_data_icon}
                          alt=""
                          style={{ height: "200px", width: "200px" }}
                        />
                        <h4>No Data Available</h4>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      {/* <Radar_chartAudit data={radar_data} /> */}
                      <Site_record_line_chart data={month_wise_data} />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Iris_footer />
    </>
  );
}

export default Audit_Section_Dashboard;
