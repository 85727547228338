import React, { useEffect, useState } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Iris_footer from "../Components/Iris_footer";

function Emp_customer_list({ base_url, swal_msg }) {
  const navigate = useNavigate();
  const storage = window.sessionStorage;
  const token = storage.getItem("token");
  const profile = JSON.parse(storage.getItem("profile"));
  const number = storage.getItem("login_mobile_no");
  const customer = JSON.parse(storage?.getItem("customer"));
  const count = storage.getItem("count");
  const customer_id = storage.getItem("customer_id");
  const [search_term, setSearch_Term] = useState("");
  const [stored_data, setStored_Data] = [];

  const [client_list, setClient_List] = useState([]);

  const [load, setLoad] = useState(false);
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const get_accounts = async () => {
    setLoad(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
      // if(search_term !=""){
        formData.append("search", `${search_term}`);
        // }
    axios
      .post(`${base_url}/get-customer-list-ajax`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setLoad(false);
        storage.setItem("count", res?.data?.data?.length);
        //   setStored_Data(res.data.data);
        setClient_List(res.data.data);
      })
      .catch((err) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: "Something went wrong.",
          customClass: {
            htmlContainer: "custom-html-container",
          },
        });
      });
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_accounts();
    }
  }, []);

  useEffect(() => {
    if (token == "" || token == null) {
      navigate("/");
    }
  }, [storage]);

  
  const get_client_details = async (iris_id,User_mode) => {
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${User_mode == "site"?"1":"0"}`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  User_mode == "site"
                  ? "2"
                  : "0"
        })
      })
      let data = await res.json();
      storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
    } catch (error) {
    }
  };

  const handleClick = (ele) => {
    storage.setItem("customer_id", ele?.customer_id);
    storage.setItem("customer_name", ele?.company_name);
    storage.setItem("is_sampling", ele?.is_sampling);
    storage.setItem("is_audit", ele?.is_audit);
    storage.setItem("iris_id", ele?.master_client_id);
    storage.setItem("master_iris_client_id", ele?.master_client_id);
    if(ele?.master_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){   //vangoo id
      get_client_details(ele?.master_client_id,"customer");
    }
    if (ele?.is_sampling != "1") {
      navigate("/dashboard");
      storage.setItem("dashboard_type", "HUB");
    } else {
      storage.setItem("dashboard_type", "IRIS");
      if (ele?.master_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347") {     //compass id
        navigate("/compass_dashboard");  
      } else {
        navigate("/schedule_list");
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    get_accounts();
  };

  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className={`${styles.equinoxLogo2} col-md-6 col-sm-12`}>
            <img src={equinoxLogo} alt="" />
          </div>

          <div className={`${styles.customerhubLogo} col-md-6 col-sm-12`}>
            <img src={hubcslogo} alt="" />
          </div>

          {/* <div
              className={`${styles.equinoxLogo} col-md-6 col-sm-12`}
              style={{ justifyContent: "flex-end", color: "#44546A" }}
            >
              <h4>Customer HUB</h4>
            </div> */}
        </div>
        <div style={{ marginBottom: "3vh" }}>
          <h3 style={{ color: "#007cc3" }}>
            {/* Welcome {profile?.capital_customer_name}! */}
            Welcome !!
          </h3>
        </div>

        {load ? (
          // <Account_load />
          <>LOADING....</>
        ) : (
          <>
            {" "}
            {client_list?.length == 0 ? (
              <h6 id={styles.site_header} className="h6">
                There is no Account registered on your number
              </h6>
            ) : client_list?.length == 1 ? (
              <h6 id={styles.site_header} className="h6">
                There is {client_list?.length} Account registered on your
                number, Please Select an Account to process further.
              </h6>
            ) : (
              <h6 id={styles.site_header} className="h6">
                There are {client_list?.length} Accounts registered on your
                number, Please Select an Account to process further.
              </h6>
            )}
            <div id={styles.filter_container}>
                  <form  onSubmit={handleSearch}>
                  {" "}
                  <input
                    type="text"
                    placeholder="Search here.."
                    value={search_term}
                    onChange={(e) => setSearch_Term(e.target.value)}
                    className="form-control"
                    // required
                  />
                                  <button
                  className="btn btn-primary"
                   type="submit"
                >
                  search
                </button>
                </form>

            </div>
            {client_list?.length == 0 ? (
              <></>
            ) : (
              <div id={styles.site_list_container}>
                <div className="col-md-6">
                  <div
                    className="col-md-12"
                    style={{ maxHeight: "54vh", overflow: "auto" }}
                  >
                    {client_list?.map((ele, index) => (
                      <>
                        <div id={styles.name_contain}>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "2vw",
                            }}
                            // onClick={()=>handleClick(ele)}
                            onClick={() => handleClick(ele)}
                          >
                            <span>{ele?.company_name}</span>
                            <PlayArrowIcon />
                            {/* <span style={{fontSize:"20px"}}>&#11208;</span> */}
                          </h2>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Iris_footer />
    </>
  );
}

export default Emp_customer_list;
