import React, { useEffect, useState, useRef } from "react";
import Drawer_customer from "../Components/Drawer";
import Scheduling_status from "../Components/Scheduling_status";
import Scheduling_Data_Table from "../Components/Scheduling_Data_Table";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import loader_img from "../Media/Business_chart.gif";
import styles from "../Styles/Audit.module.css";
import no_data_icon from "../Media/no_data_icon2.png";
import { DateRangePicker } from "rsuite";

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import Upcoming_schedule_tbl from "../Components/Upcoming_schedule_tbl";
import addWeeks from "date-fns/addWeeks";
import Footer from "../Components/footer";
import Iris_footer from "../Components/Iris_footer";
import Scheduling_status_change from "../Components/Scheduling_status_change";
import Scheduling_Data_Table_change from "../Components/Scheduling_Data_Table_change";
import { useNavigate, useSearchParams } from "react-router-dom";
import Last_seven_schedule_tbl from "../Components/Last_seven_schedule_tbl";
import Summary_piechart from "../Components/Summary_piechart";

function Schedule_List_v2({ base_url, compass_flag }) {
  const storage = window.sessionStorage;
  let mobile_no = storage.getItem("login_mobile_no");
  let profile = JSON.parse(storage.getItem("profile"));
  let token = storage.getItem("token");
  let iris_id = storage.getItem("iris_id");
  let User_mode = storage.getItem("User_mode");
  let master_iris_client_id = storage.getItem("master_iris_client_id");
  let iris_logo_image = storage.getItem("iris_logo_image");
  let IS_CAPA_APPLICABLE = storage.getItem("IS_CAPA_APPLICABLE");

  let is_sampling = storage.getItem("is_sampling");
  let is_audit = storage.getItem("is_audit");
  let site_type = storage.getItem("site_type");

  const navigate = useNavigate();

  const [schedulePieData, setSchedulePieData] = useState([]);
  const [scheduleTableData, setScheduleTableData] = useState([]);
  let dateFormat = "MM/YYYY";
  let format2 = moment().format("MM/YYYY");
  const [month_filter, setMonth_filter] = useState(new Date());
  const [month, setMonth] = useState(Number(format2?.split("/")[0]));
  const [year, setYear] = useState(Number(format2?.split("/")[1]));
  const [loadPie, setLoadPie] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [upcoming_Load, setUpcoming_Load] = useState(false);

  const [audit_fill_status, setAudit_Fill_Status] = useState("");
  const [audit_fill_data, setAudit_Fill_data] = useState([]);

  const [capa_fill_status, setCapa_Fill_status] = useState("");
  const [capa_fill_data, setcapa_Fill_Data] = useState([]);

  const [fill_status, setFill_status] = useState("");
  const [fill_data, setFill_data] = useState([]);

  const [logo_url, setLogo_Url] = useState("");

  const [upcoming_data, setUpcoming_Data] = useState([]);

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [selectedFromDate, setSelectedFromDate] = useState(firstDay);
  const [selectedToDate, setSelectedToDate] = useState(lastDay);

  const windowHeight = useRef(window.innerHeight);

  const [seven_audits_data, setSeven_audits_Data] = useState([]);
  const [seven_load, setSeven_Load] = useState(false);

  const handleDateChange = (date, dateString) => {
    if (date) {
      setMonth_filter(date);
      // setSend_Month_filter(moment(date.$d).format("MM/YYYY"));
      let date_info = moment(date.$d).format("MM/YYYY").split("/");
      setMonth(Number(date_info[0]));
      setYear(Number(date_info[1]));
    } else {
      setMonth_filter(new Date());
      let format2 = moment().format("MM/YYYY");
      setMonth(Number(format2?.split("/")[0]));
      setYear(Number(format2?.split("/")[1]));
      // setSend_Month_filter(format2)
    }
  };

  const get_dashboard_data_chart_status = async (start_date, end_date) => {
    setLoadPie(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE_VAANGO_V1/${newStartDate}/${newEndDate}/${iris_id}/${
      //     site_type == "all"
      //       ? "2"
      //       : User_mode == "site"
      //       ? "1"
      //       : "0"
      //   }`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE_VAANGO_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          StartDate:newStartDate,
          EndDate:newEndDate,
          ID:iris_id,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
      let data = await res.json();
      setLoadPie(false);
      if (data?.response?.CODE == "200") {
        setSchedulePieData(data?.data);
      } else {
        setSchedulePieData([]);
      }
    } catch (error) {
      setLoadPie(false);
      setSchedulePieData([]);
    }
  };

  const get_dashboard_data_table = async (start_date, end_date) => {
    setLoadTable(true);
    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    setAudit_Fill_data([]);
    setcapa_Fill_Data([]);
    setScheduleTableData([]);
    setFill_data([]);
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE_TABLE_V1/${newStartDate}/${newEndDate}/${iris_id}/${
      //     site_type == "all"
      //       ? "2"
      //       : User_mode == "site"
      //       ? "1"
      //       : "0"
      //   }`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_AUDIT_STATUS_WISE_TABLE_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          StartDate:newStartDate,
          EndDate:newEndDate,
          ID:iris_id,
          LOGIN_TYPE:  site_type == "all"
                  ? "2"
                  : User_mode == "site"
                  ? "1"
                  : "0"
        })
      })
      let data = await res.json();
      setLoadTable(false);
      if (data?.response?.CODE == "200") {
        setScheduleTableData(data?.data);
      } else {
        setScheduleTableData([]);
      }
    } catch (error) {
      setLoadTable(false);
      setScheduleTableData([]);
    }
  };

  const get_upcoming_schedule = async () => {
    setUpcoming_Load(true);
    try {
      let res = await fetch(
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_DASHBOARD_DATA_UPCOMING_SCHEDULED/${mobile_no}`
      );
      let data = await res.json();
      setUpcoming_Load(false);
      if (data?.response?.CODE == "200") {
        setUpcoming_Data(data?.data);
      } else {
        setUpcoming_Data([]);
      }
    } catch (error) {
      setUpcoming_Load(false);
      setUpcoming_Data([]);
    }
  };

  // const get_client_details = async() =>{

  //   try {
  //     let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${site_type == "all"?2:User_mode == "site"?"1":"0"}`);
  //     let data = await res.json();
  //     setLogo_Url(data?.data[0]?.LOGO_IMG)
  //     storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
  //   } catch (error) {

  //   }
  // }

  const get_seven_audits_data = async () => {
    try {
      setSeven_Load(true);
      let res = await fetch(
        `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_LAST_SEVEN_DAYS_SITE_DETAILS/${mobile_no}`
      );
      let data = await res.json();
      setSeven_Load(false);
      setSeven_audits_Data(data?.data);
    } catch (error) {
      setSeven_Load(false);
      setSeven_audits_Data([]);
    }
  };

  const handleRangeChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
    }
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_dashboard_data_chart_status(selectedFromDate, selectedToDate);
      if (compass_flag) {
        let x = 1;
      } else {
        get_dashboard_data_table(selectedFromDate, selectedToDate);
      }
    }
  }, [selectedFromDate]);

  useEffect(() => {
    if (token != "" && token != null) {
      if (compass_flag) {
        let x = 1;
      } else {
        // get_upcoming_schedule();
      }
    }
  }, []);

  // useEffect(()=>{
  // get_seven_audits_data();
  // },[])

  const handleAudit = (val_filter) => {
    setCapa_Fill_status("");
    setAudit_Fill_Status(val_filter);
    setFill_status("");

    let filter_data = scheduleTableData?.filter((ele) => {
      return ele.AUDIT_RESULT == val_filter;
    });
    setcapa_Fill_Data([]);
    setFill_data([]);
    setAudit_Fill_data(filter_data);
  };

  const handleCAPA = (val_filter) => {
    if (val_filter == "CAPA") {
      setAudit_Fill_Status("");
      setCapa_Fill_status(val_filter);
      setFill_status("");
      let filter_data = scheduleTableData?.filter((ele) => {
        return ele.FINAL_PA_N_CA_STATUS != "";
      });
      setAudit_Fill_data([]);
      setFill_data([]);
      setcapa_Fill_Data(filter_data);
    } else {
      setAudit_Fill_Status("");
      setCapa_Fill_status(val_filter);
      setFill_status("");
      let filter_data = scheduleTableData?.filter((ele) => {
        return ele.FINAL_PA_N_CA_STATUS == val_filter;
      });
      setAudit_Fill_data([]);
      setFill_data([]);
      setcapa_Fill_Data(filter_data);
    }
  };

  const handleFilter = (val_filter) => {
    if (val_filter == "Created") {
      setFill_status(val_filter);
      setAudit_Fill_Status("");
      setCapa_Fill_status("");
      setcapa_Fill_Data([]);
      setAudit_Fill_data([]);
      setFill_data(scheduleTableData);
    } else {
      setFill_status(val_filter);
      setAudit_Fill_Status("");
      setCapa_Fill_status("");
      let filter_data = scheduleTableData?.filter((ele) => {
        return ele.REPORT_STATUS == val_filter;
      });
      setcapa_Fill_Data([]);
      setAudit_Fill_data([]);
      setFill_data(filter_data);
    }
  };

  let verify_count = 0;
  let submitted_count = 0;
  let open_count = 0;
  let expire_count = 0;
  let pass_count = 0;
  let fail_count = 0;
  let created_count = 0;
  let schedule_count = 0;
  let qc_count = 0;
  let report_submitted_count = 0;

  scheduleTableData?.forEach((ele) => {
    if (ele.FINAL_PA_N_CA_STATUS == "OPEN") {
      open_count++;
    }
    if (ele.FINAL_PA_N_CA_STATUS == "VERIFIED") {
      verify_count++;
    }
    if (ele.FINAL_PA_N_CA_STATUS == "SUBMITTED") {
      submitted_count++;
    }
    if (ele.FINAL_PA_N_CA_STATUS == "EXPIRED") {
      expire_count++;
    }
    if (ele.AUDIT_RESULT == "PASS") {
      pass_count++;
    }
    if (ele.AUDIT_RESULT == "FAIL") {
      fail_count++;
    }

    if (ele.REPORT_STATUS == "Created") {
      created_count++;
    }
    if (ele.REPORT_STATUS == "Schedule") {
      schedule_count++;
    }
    if (ele.REPORT_STATUS == "Qc") {
      qc_count++;
    }

    if (ele.REPORT_STATUS == "Report_Submitted") {
      report_submitted_count++;
    }
  });

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{
          paddingLeft: "5em",
          paddingTop: "0em",
          paddingRight: "1.5em",
          marginTop: "-25px",
        }}
      >
        {/* <div className="row"> */}

        {/* {compass_flag? <div className="row">
           <h3 id={styles.client_name}>Compass</h3>
        </div>:<></>} */}

        <div
          className="row"
          id={styles.tabs_container}
          style={{ marginTop: "-30px" }}
        >
          <div
            style={{
              borderBottom: "2px solid #007cc3",
              padding: "5px",
              backgroundColor: "rgb(0 124 195 / 16%)",
            }}
            onClick={() => navigate("/schedule_list")}
            className="col-1"
          >
            <p>Summary</p>
          </div>
          <div
            style={{ backgroundColor: "rgb(0 124 195 / 16%)" }}
            onClick={() => navigate("/audit_dashboard_v2")}
            className="col-2"
          >
            <p>State/City Wise</p>
          </div>
          <div
            style={{ backgroundColor: "rgb(0 124 195 / 16%)" }}
            onClick={() => navigate("/audit_section_report_v2")}
            className="col-1"
          >
            <p>Section Wise</p>
          </div>
          <div className="col-sm-12 col-md-3">
            {loadTable ? (
              <></>
            ) : (
              <DateRangePicker
                format="dd MMM yyyy"
                ranges={predefinedRanges}
                // placeholder="Placement left"
                style={{ width: 250, marginTop: "-5px" }}
                value={[selectedFromDate, selectedToDate]}
                onChange={handleRangeChange}
                // onShortcutClick={(shortcut, event) => {

                // }}
                preventOverflow
              />
            )}
          </div>
        </div>
        {master_iris_client_id == "a95226c4-9917-4db8-9009-0481155bae76" ? (
          <img src={iris_logo_image} alt="text" id={styles.iris_logo_img} />
        ) : (
          <></>
        )}
        {
          loadTable ? <></> : <></>
          //  <div className="row mt-3">
          //     <div className="col-sm-12 col-md-2">
          //       {/* <h5 style={{ color: "#4F4F4F" }}>
          //         Total Site: {schedulePieData[0]?.TOTAL_SITES}
          //       </h5> */}
          //     </div>
          //     <div className="col-sm-12 col-md-7">

          //     </div>
          //     <div className="col-sm-12 col-md-3">
          //       {/* <Space direction="vertical">
          //         <DatePicker
          //           onChange={handleDateChange}
          //           picker="month"
          //           defaultValue={dayjs(format2, dateFormat)}
          //           value={dayjs(month_filter)}
          //           format="MMM-YYYY"
          //         />
          //       </Space> */}

          //       <DateRangePicker
          //         format="dd MMM yyyy"
          //         ranges={predefinedRanges}
          //         // placeholder="Placement left"
          //         style={{ width: 300 }}
          //         value={[selectedFromDate, selectedToDate]}
          //         onChange={handleRangeChange}
          //         // onShortcutClick={(shortcut, event) => {

          //         // }}
          //         preventOverflow
          //       />
          //     </div>
          //   </div>
        }

        {/* new change from client on date = 13 Nov */}

        {/* {loadPie ? (
          <div className="row" id={styles.loader_contain}>
            <img src={loader_img} alt="" />
          </div>
        ) : schedulePieData.length > 0 ? (
          <Scheduling_status_change data={schedulePieData[0]} />
        ) : (
          <div className="col-12">
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Available</h4>
          </div>
        )} */}

        {/* <div className="row">
          <div>
            <Summary_piechart pass_count={pass_count} fail_count={fail_count} report_submitted_count={report_submitted_count} />
          </div>
        </div> */}

        {loadTable ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "30px",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                borderColor: "#28a745",
                backgroundColor: "#28a74521",

                pointerEvents:
                  schedulePieData[0]?.TOTAL_SITES == 0 ? "none" : "",
                boxShadow:
                  fill_status == "" &&
                  capa_fill_status == "" &&
                  audit_fill_status == ""
                    ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    : "",
              }}
              onClick={() => handleFilter("")}
              id={styles.audit_countSection}
            >
              <h3>{schedulePieData[0]?.TOTAL_SITES}</h3>
              <p>Total Sites</p>
            </div>
            <div
              style={{
                borderColor: "#007bff",
                backgroundColor: "#007bff2b",
                pointerEvents: scheduleTableData?.length == 0 ? "none" : "",
                boxShadow:
                  fill_status == "Created"
                    ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    : "",
              }}
              onClick={() => handleFilter("Created")}
              id={styles.audit_countSection}
            >
              <h3>{scheduleTableData?.length}</h3>
              <p>No. Of Audits</p>
            </div>
            <div
              style={{
                borderColor: "#ffc107",
                backgroundColor: "rgb(255 193 7 / 19%)",
                pointerEvents: schedule_count == 0 ? "none" : "",
                boxShadow:
                  fill_status == "Schedule"
                    ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    : "",
              }}
              onClick={() => handleFilter("Schedule")}
              id={styles.audit_countSection}
            >
              <h3>{schedule_count}</h3>
              <p>Schedule</p>
            </div>
            <div
              style={{
                borderColor: "#ffc107",
                backgroundColor: "rgb(255 193 7 / 19%)",
                pointerEvents: qc_count == 0 ? "none" : "",
                boxShadow:
                  fill_status == "Qc" ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "",
              }}
              onClick={() => handleFilter("Qc")}
              id={styles.audit_countSection}
            >
              <h3>{qc_count}</h3>
              <p>QC</p>
            </div>
            <div
              style={{
                borderColor: "#28a745",
                backgroundColor: "#28a74521",
                boxShadow:
                  fill_status == "Report_Submitted" ||
                  audit_fill_status == "PASS" ||
                  audit_fill_status == "FAIL"
                    ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    : "",
              }}
              id={styles.capa_counSection}
            >
              <div style={{ paddingTop: "10px" }}>
                <h3>{report_submitted_count}</h3>
                <p
                  style={{
                    pointerEvents: report_submitted_count == 0 ? "none" : "",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                  onClick={() => handleFilter("Report_Submitted")}
                >
                  Report Done
                </p>
              </div>
              <div
                style={{ borderLeft: "1px solid grey", paddingLeft: "10px" }}
              >
                <p
                  style={{ pointerEvents: pass_count == 0 ? "none" : "" }}
                  onClick={() => handleAudit("PASS")}
                >
                  Pass: <span>{pass_count}</span>
                </p>
                <p
                  style={{ pointerEvents: fail_count == 0 ? "none" : "" }}
                  onClick={() => handleAudit("FAIL")}
                >
                  Fail: <span>{fail_count}</span>
                </p>
              </div>
            </div>

        {IS_CAPA_APPLICABLE == "1"?    <div
              style={{
                borderColor: "#6c757d",
                backgroundColor: "rgb(108 117 125 / 14%)",
                boxShadow:
                  capa_fill_status != ""
                    ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    : "",
              }}
              id={styles.capa_counSection}
            >
              <div style={{ paddingTop: "10px" }}>
                <h3>
                  {Number(open_count) +
                    Number(submitted_count) +
                    Number(verify_count) +
                    Number(expire_count)}
                </h3>
                <p
                  onClick={() => handleCAPA("CAPA")}
                  style={{
                    // pointerEvents: "none",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  CAPA
                </p>
              </div>
              <div
                style={{ borderLeft: "1px solid grey", paddingLeft: "10px" }}
              >
                <p
                  style={{ pointerEvents: open_count == 0 ? "none" : "" }}
                  onClick={() => handleCAPA("OPEN")}
                >
                  Open : <span>{open_count}</span>
                </p>
                <p
                  style={{ pointerEvents: submitted_count == 0 ? "none" : "" }}
                  onClick={() => handleCAPA("SUBMITTED")}
                >
                  Submitted : <span>{submitted_count}</span>
                </p>
                <p
                  style={{ pointerEvents: verify_count == 0 ? "none" : "" }}
                  onClick={() => handleCAPA("VERIFIED")}
                >
                  Verify : <span>{verify_count}</span>
                </p>
                <p
                  style={{ pointerEvents: expire_count == 0 ? "none" : "" }}
                  onClick={() => handleCAPA("EXPIRED")}
                >
                  Expired : <span>{expire_count}</span>
                </p>
              </div>
            </div>:<></>}

            {/* <div>
  <Last_seven_schedule_tbl data={seven_audits_data} />
</div> */}
          </div>
        )}

        {/* {compass_flag?<></>:<>
        {upcoming_Load?<div className="row" id={styles.loader_contain}>
            <img src={loader_img} alt="" />
          </div> :<div id={styles.schedule_tbl} className="row mt-2">
       <Upcoming_schedule_tbl data={upcoming_data}/>
        </div>}
        </>} */}

        {/* {compass_flag ? (
          <></>
        ) : ( */}
        <>
          {loadTable ? (
            <div className="row mt-2" id={styles.loader_contain}>
              <img src={loader_img} alt="" />
            </div>
          ) : scheduleTableData.length > 0 ? (
            <div id={styles.schedule_tbl} className="row mt-4 mb-5">
              <Scheduling_Data_Table_change
                audit_fill_status={audit_fill_status}
                audit_fill_data={audit_fill_data}
                capa_fill_status={capa_fill_status}
                capa_fill_data={capa_fill_data}
                fill_status={fill_status}
                fill_data={fill_data}
                tableData={scheduleTableData}
              />
            </div>
          ) : (
            <div className="col-12">
              <img
                src={no_data_icon}
                alt=""
                style={{ height: "200px", width: "200px" }}
              />
              <h4>No Data Available</h4>
            </div>
          )}
        </>
        {/* )} */}
      </div>

      {/* </div> */}
      {/* <Footer/> */}
      <Iris_footer />
    </>
  );
}

export default Schedule_List_v2;
