import React, { useState, useEffect } from "react";
import styles from "../Styles/LoginStyle.module.css";
import bg_img from "../Media/login_new.jpg";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import hubcslogo from "../Media/ECC Logo.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Tooltip } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate, useSearchParams } from "react-router-dom";

function Employee_login({ base_url }) {
  const storage = window.sessionStorage;

  const [emp_code, setEmp_Code] = useState("");
  const [error_msg, setError_Msg] = useState("");
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [show_timer, setShow_Timer] = useState(false);
  const [show_inp, setShow_Inp] = useState(false);
  const [send_btn, setSend_Btn] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [customer_id, setCustomer_Id] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  let timer;
  const [year_show, setYear_Show] = useState(new Date());
  let info_arr = year_show?.toString()?.split(" ");

  const swal_msg = (icon, msg) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      text: msg,
      customClass: {
        htmlContainer: "custom-html-container",
      },
    });
  };

  const get_client_details = async (iris_id,User_mode) => {
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${User_mode == "site"?"1":"0"}`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  User_mode == "site"
                  ? "2"
                  : "0"
        })
      })
      let data = await res.json();
      storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
      storage.setItem("IS_CAPA_APPLICABLE",data?.data[0]?.IS_CAPA_APPLICABLE)
    } catch (error) {
    }
  };

  const get_accounts = async (mobileNo, token, profile) => {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    if (customer_id != undefined) {
      formData.append("customer_id", `${customer_id}`);
    }
    axios
      .post(`${base_url}/get-customer-list-ajax`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        storage.setItem("login_type", "employee_login");
        if (customer_id != undefined) {
          storage.setItem("count", res?.data?.data?.length);
          storage.setItem("customer_id", res?.data?.data[0]?.customer_id);
          storage.setItem("iris_name", res?.data?.data[0]?.iris_site_name);
          storage.setItem("customer_name", res?.data?.data[0]?.company_name);
          storage.setItem("iris_id", profile?.master_client_id);
          storage.setItem("master_iris_client_id", profile?.master_client_id);
          // if(profile?.master_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){         //vangoo id
          if (profile?.is_audit == "1") {
            get_client_details(profile?.master_client_id,"customer");
          }
          // }
          if (profile?.is_sampling != "1") {
            navigate("/dashboard");
          } else {
            storage.setItem("dashboard_type", "IRIS");
            if (
              profile?.master_client_id ==
              "f351d8b4-e52a-ea11-a961-000d3a584347"
            ) {
              navigate("/compass_dashboard");
            } else {
              navigate("/schedule_list");
            }
          }
        } else {
          navigate("/employee_customer_list");
          // storage.setItem("count", res?.data?.data?.length);
          // storage.setItem("customer_id", res?.data?.data[0]?.customer_id);
          // storage.setItem("iris_name", res?.data?.data[0]?.iris_site_name);
          // storage.setItem(
          //   "customer_name",
          //   res?.data?.data[0]?.capital_customer_name
          // );
          // storage.setItem("iris_id", profile?.master_client_id);
          // storage.setItem("master_iris_client_id", profile?.master_client_id);

          // // here is is_sampling != 1 means its true   (Customer Login)

          // if (res?.data?.data?.length == 1) {
          //   if (profile?.is_sampling != "1") {
          //     navigate("/dashboard");
          //   } else {
          //     storage.setItem("dashboard_type", "IRIS");
          //     if (
          //       profile?.master_client_id ==
          //       "f351d8b4-e52a-ea11-a961-000d3a584347"
          //     ) {
          //       navigate("/compass_dashboard");
          //     } else {
          //       navigate("/schedule_list");
          //     }
          //   }
          // } else {
          //   if (profile?.is_sampling != "1") {
          //     navigate("/accountsList");
          //   } else {
          //     storage.setItem("dashboard_type", "IRIS");
          //     if (
          //       profile?.master_client_id ==
          //       "f351d8b4-e52a-ea11-a961-000d3a584347"
          //     ) {
          //       navigate("/compass_dashboard");
          //     } else {
          //       navigate("/schedule_list");
          //     }
          //   }

          // }
        }
      })
      .catch((err) => {
        swal_msg("error", "Something went wrong.");
      });
  };

  const check_login = async (val) => {
    setSend_Btn(true);
    const formData = new FormData();
    formData.append("emp_code", `${emp_code}`);
    formData.append("otp_no", `${val}`);
    formData.append("mobile_no", `${mobileNo}`);
    if (customer_id != undefined) {
    formData.append("customer_id", `${customer_id}`);
    }
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "Multipart/form-data",
      },
    };
    axios
      .post(`${base_url}/employee-login`, formData, config, {
        mode: "no-cors",
      })
      .then((res) => {
        setSend_Btn(false);
        if (res?.data?.code == 200) {
          swal_msg("success", res?.data?.message);
          let profile = res.data["user"].profile_details;
          let token = res.data["user"].token.access_token;
          storage.setItem("profile", JSON.stringify(profile));
          storage.setItem("token", JSON.stringify(token));
          storage.setItem("User_mode", "customer");
          storage.setItem("login_mobile_no", mobileNo);
          storage.setItem("is_sampling", profile?.is_sampling);
          storage.setItem("is_audit", profile?.is_audit);
          storage.setItem("dashboard_type", "HUB");

          if (mobileNo == "7304496662") {
            navigate("/zomato_dashboard");
          } else {
            get_accounts(mobileNo, token, profile);
          }
          // }
        } else {
          swal_msg("error", res?.data?.message);
        }
      })
      .catch((err) => {
        setSend_Btn(false);
        setOtp("");
        setShow_Timer(false);
        swal_msg("error", "Please Enter Valid OTP.");
      });
  };

  const handleLogin = (newValue) => {
    setOtp(newValue);
    if (newValue?.length == 6) {
      check_login(newValue);
    }
  };
  const start_timer = () => {
    setShow_Timer(true);
    setSeconds(30);
  };

  const emp_send_otp = async (val) => {
    setSend_Btn(true);
    setMobileNo("");
    const formData = new FormData();
    formData.append("empolyee_code", `${val}`);
    axios
      .post(`${base_url}/send-employee-otp`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          swal_msg("success", res.data.message);
          setMobileNo(res?.data?.mobile_no);
          setOtp("");
          start_timer();
          setSend_Btn(false);
          setShow_Inp(true);
        } else {
          swal_msg("error", res.data.message);
          setError_Msg(res?.data?.message);
          setSend_Btn(false);
          setShow_Inp(false);
          setMobileNo("");
        }
      })
      .catch((err) => {
        swal_msg("error", "Something went wrong.");
        setSend_Btn(false);
        setShow_Inp(false);
        setError_Msg("");
        setMobileNo("");
      });
  };

  const sending_otp = (e) => {
    setEmp_Code(e.target.value.toUpperCase());
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      emp_send_otp(e.target.value.toUpperCase());
    }
  };

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    if (seconds == 0) {
      clearInterval(timer);
      setShow_Timer(false);
      // setShow_btn(true);
      // setIsDisabled(false);
    }
    return () => clearInterval(timer);
  });

  useEffect(() => {
    const emp_arr = searchParams.get("emp_code");
    if (emp_arr != null) {
      let ids_arr = emp_arr.split(",");
      setEmp_Code(ids_arr[0]);
      setCustomer_Id(ids_arr[1]);
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" id={styles.top_bar}>
            <div id={styles.container}>
              <div className="row">
                <div className="col-xl-12 d-flex flex-row ">
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <PhoneIcon />
                    </div>

                    <a href="tel:02250647422">022 506 474 22</a>
                  </div>
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <EmailIcon />
                    </div>

                    <a href="mailto:contact@equinoxlab.com">
                      contact@equinoxlab.com
                    </a>
                  </div>
                  <div
                    style={{ marginLeft: "auto" }}
                    id={styles.top_bar_contact_item}
                  ></div>

                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_social}>
                      <ul id={styles.social_icons}>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/equinox-labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Linkedin" placement="bottom">
                              <LinkedInIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.instagram.com/equinox_labs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Instagram" placement="bottom">
                              <InstagramIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.facebook.com/equinoxlabs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Facebook" placement="bottom">
                              <FacebookOutlinedIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/Equinox_Labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Twitter" placement="bottom">
                              <XIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.youtube.com/@equinoxlabs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Youtube" placement="bottom">
                              <YouTubeIcon />
                            </Tooltip>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.equinoxLogo} col-12`}>
            {/* <img style={{marginTop:"10px"}} src={equinoxLogo} alt="" /> */}

            <img style={{ marginTop: "10px" }} src={hubcslogo} alt="" />
          </div>

          <div className="col-12">
            <div
              className="row"
              style={{ justifyContent: "space-around", alignItems: "center" }}
            >
              <div className="col-7">
                <img id={styles.login_pic} src={bg_img} alt="" />
              </div>
              <div className="col-4">
                <div id={styles.loginCard}>
                  <div className={styles.welcome_header}>
                    <h5>Welcome to Equinox Labs</h5>
                    <p className={styles.register_msg}>
                      If you are not a Equinox Client yet please&nbsp;
                      <a
                        href="https://www.equinoxlab.com/contact.php"
                        target="_blank"
                      >
                        click here
                      </a>
                      &nbsp;to register yourself, Our team will help you
                      on-board
                    </p>
                  </div>
                  <div>
                    <TextField
                      id="standard-basic"
                      className={styles.number_inp}
                      label="Employee Code"
                      type="text"
                      value={emp_code}
                      variant="standard"
                      sx={{
                        width: "80%",
                        fontSize: "18px",
                        color: "#44546A",
                        fontFamily: "Roboto",
                      }}
                      onKeyUp={sending_otp}
                      onChange={(e) =>
                        setEmp_Code(e.target.value.toUpperCase())
                      }
                      disabled={send_btn}
                    />
                    {error_msg?.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          width: "90%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginTop: "5px",
                            textAlign: "right",
                            width: "80%",
                            color: "red",
                          }}
                        >
                          {error_msg}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {show_inp ? (
                      <>
                        <div>
                          <p className={styles.otpLabel}>Enter OTP</p>
                          <MuiOtpInput
                            value={otp}
                            length={6}
                            onChange={(newValue) => handleLogin(newValue)}
                            sx={{
                              width: "80%",
                              margin: "auto",

                              // marginTop: "30px",
                              gap: "10px",
                            }}
                            required
                            // onKeyUp={handleInput}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {show_timer ? (
                      <div>
                        <p
                          id={styles.timer}
                          style={{
                            color: seconds < 10 ? "red" : "green",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          <span>Resend in</span> {seconds} Seconds
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className={styles.cardFooter}>
                    <p>
                      In case you face any issue while logging in the portal
                      please reach out to us on{" "}
                      <a href="mailto:contact@equinoxlab.com">
                        contact@equinoxlab.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id={styles.footer_container}>
            <div className="col-12">
              <div className="row" id={styles.footerCopyright}>
                <div className="col-9">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                  <p id={styles.copyright}>
                    {/* Copyright : Equinox Labs Pvt ltd-{info_arr[3]} */}
                    Copyright © {info_arr[3]} Equinox Labs Private Limited
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Employee_login;
