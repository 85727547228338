import axios from "axios";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import styles from "../Styles/Table.module.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Confirm_user_status from "../Modals/Confirm_user_status";
import AddUpdate_user from "../Modals/AddUpdate_user";
import loader_img from "../Media/Business_chart.gif";
import Drawer_customer from "../Components/Drawer";
import Footer from "../Components/footer";
import { Chip } from "@mui/material";
import Site_pop_up from "../Modals/Site_pop_up";
import { Dropdown } from "antd";

function Add_user({ base_url }) {
  const storage = window.sessionStorage;
  let token = storage.getItem("token");
  let customer_id = storage.getItem("customer_id");
  let mobile_no = storage.getItem("login_mobile_no");
  let profile = JSON.parse(storage.getItem("profile"));

  let is_audit = storage.getItem("is_audit")
  let is_sampling = storage.getItem("is_sampling");
  let master_iris_client_id = storage.getItem("iris_id");

  const [user_load, setUser_Load] = useState(false);
  const [user_list, setUser_List] = useState([]);
  const [confirm_msg, setConfirm_Msg] = useState("");
  const [individual_userid, setIndividual_UserId] = useState("");
  const [status_load, setStatus_Load] = useState(false);

  const [site_load, setSite_Load] = useState(false);
  const [site_list, setSite_List] = useState([]);
  const [selected_site, setSelected_Site] = useState([]);

  const [iris_site_list, setIris_Site_List] = useState([]);
  const [selected_iris_site, setSelected_Iris_Site] = useState([]);

  const [action_load, setAction_Load] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [new_mobile,setNew_Mobile] = useState("")
  const [email, setEmail] = useState("");
  const [site_user_id, setSite_User_Id] = useState("");

  const [checked_sampling, setChecked_Sampling] = React.useState(false);
  const [checked_audit, setChecked_Audit] = React.useState(false);

  const [site_data, setSite_Data] = useState([]);
  const [customer_code,setCustomer_code] = useState("")

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);

  const swal_msg = (icon, msg) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      text: msg,
      customClass: {
        htmlContainer: "custom-html-container",
      },
    });
  };

  const get_user_list = async () => {
    setUser_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("primary_user_id", customer_id);
    formData.append("customer_id", customer_id);
    axios
      .post(`${base_url}/customers-site-get-all-users`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setUser_Load(false);
        if (res?.data?.code == "200") {
          setUser_List(res?.data?.data);
        } else {
          setUser_List([]);

          swal_msg(
            res?.data?.message == "Users not found." ? "info" : "error",
            res?.data?.message
          );
        }
      })
      .catch((err) => {
        setUser_Load(false);
        setUser_List([]);
        swal_msg("error", "Something went wrong.");
      });
  };

  const delete_user = async () => {
    setStatus_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("mobile_no", mobile);
    formData.append("customer_code", customer_code);
    axios
      .post(`${base_url}/delete-customer-site-user`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setStatus_Load(false);
        if (res?.data?.code == "200") {
          get_user_list();
          swal_msg("success", res?.data?.Details);
          handleDelete_Reset();
        } else {
          swal_msg("error", res?.data?.Details);
        }
      })
      .catch((err) => {
        setStatus_Load(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const activate_user = async () => {
    setStatus_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("id", `${individual_userid}`);
    axios
      .post(`${base_url}/activate-deleted-customer-site-user`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setStatus_Load(false);
        if (res?.data?.code == "200") {
          get_user_list();
          swal_msg("success", res?.data?.Details);
          handleClose();
        } else {
          swal_msg("error", res?.data?.Details);
        }
      })
      .catch((err) => {
        setStatus_Load(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const get_site_list = async () => {
    setSite_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("mobile_number", mobile_no);
    formData.append("customer_id", customer_id);
    axios
      .post(`${base_url}/get-site-list-with-custcode`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setSite_Load(false);
        if (res?.data?.code == "200") {
          let site_arr = [];
          for (let i = 0; i < res?.data?.data?.length; i++) {
            site_arr.push({
              label: res?.data?.data[i]?.site_name,
              value: res?.data?.data[i]?.site_id,
            });
          }
          setSite_List(site_arr);
        } else {
          swal_msg(
            res?.data?.Details == "Site not found" ? "info" : "error",
            res?.data?.Details
          );
        }
      })
      .catch((err) => {
        setSite_Load(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const get_iris_site_list = async () => {
    try {
      let res = await fetch(
        `https://irisauditor.com/api/Site_Management.svc/get_sites_under_masterclient/${master_iris_client_id}`
      );
      let data = await res.json();
      let site_arr = [];
      for (let i = 0; i < data?.data?.length; i++) {
        site_arr.push({
          label: data?.data[i].NAME,
          value: data?.data[i].SITE_GUID,
        });
      }
      setIris_Site_List(site_arr);
    } catch (error) {
      setIris_Site_List([]);
    }
  };

  const add_site_user = async () => {
    setAction_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    let send_site_arr = [];
    for (let i = 0; i < selected_site?.length; i++) {
      send_site_arr.push(selected_site[i]?.value);
    }
    let send_iris_id = [];
    for (let i = 0; i < selected_iris_site?.length; i++) {
      send_iris_id.push(selected_iris_site[i]?.value);
    }
    let send_iris_name = [];
    for (let i = 0; i < selected_iris_site?.length; i++) {
      send_iris_name.push(selected_iris_site[i]?.label);
    }
    const formData = new FormData();
    formData.append("site_user_name", `${name}`);
    if (send_site_arr?.length > 0) {
      formData.append("site_id", `${send_site_arr?.toString()}`);
    }
    formData.append("mobile_no", `${mobile}`);
    formData.append("email", `${email}`);
    formData.append("primary_user_id", `${customer_id}`);
    formData.append("customer_id", `${customer_id}`);
    formData.append("customer_code", `${profile?.customer_code}`);
    if (selected_iris_site?.length > 0) {
      formData.append("master_site_client_id", `${send_iris_id?.toString()}`);
      formData.append("iris_site_name", `${send_iris_name?.join("%#")}`);
    }
    if (checked_audit) {
      formData.append("is_audit", `1`);
    }
    if (checked_sampling) {
      formData.append("is_hub", `1`);
    }

    axios
      .post(`${base_url}/customers-site-add-user-with-site-code`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setAction_Load(false);
        if (res?.data?.code == "200") {
          handleModalRefresh();
          get_user_list();
          swal_msg("success", res?.data?.message);
        } else {
          swal_msg("error", res?.data?.message);
        }
      })
      .catch((err) => {
        setAction_Load(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const edit_site_user = async () => {
    setAction_Load(true);
    let send_site_arr = [];
    for (let i = 0; i < selected_site?.length; i++) {
      send_site_arr.push(selected_site[i]?.value);
    }
    let send_iris_id = [];
    for (let i = 0; i < selected_iris_site?.length; i++) {
      send_iris_id.push(selected_iris_site[i]?.value);
    }
    let send_iris_name = [];
    for (let i = 0; i < selected_iris_site?.length; i++) {
      send_iris_name.push(selected_iris_site[i]?.label);
    }
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("user_name", `${name}`);
    if (send_site_arr?.length > 0) {
      formData.append("site_id", `${send_site_arr?.toString()}`);
    }
    formData.append("new_mobile_no", `${new_mobile}`);
    formData.append("mobile_no", `${mobile}`);
    formData.append("email", `${email}`);
    formData.append("primary_user_id", `${customer_id}`);
    formData.append("customer_id", `${customer_id}`);
    formData.append("id", `${site_user_id}`);
    if (selected_iris_site?.length > 0) {
      formData.append("master_site_client_id", `${send_iris_id?.toString()}`);
      formData.append("iris_site_name", `${send_iris_name?.join("%#")}`);
    }
    if (checked_audit) {
      formData.append("is_audit", `1`);
    }
    if (checked_sampling) {
      formData.append("is_hub", `1`);
    }
    axios
      .post(`${base_url}/update-customer-site-user`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setAction_Load(false);
        if (res?.data?.code == "200") {
          handleModalRefresh();
          get_user_list();
          swal_msg("success", res?.data?.Details);
        } else {
          swal_msg("error", res?.data?.Details);
        }
      })
      .catch((err) => {
        setAction_Load(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const handleModalRefresh = () => {
    setName("");
    setMobile("");
    setNew_Mobile("")
    setEmail("");
    setSelected_Site([]);
    setSelected_Iris_Site([]);
    setChecked_Sampling(false);
    setChecked_Audit(false);
    handleClose3();
    handleClose4();
  };

  const handleDelete_Reset = ()=>{
    setMobile("");
    setCustomer_code("");
    handleClose();
  }


  const handleEdit = (info) => {
    setSite_User_Id(info?.rowData[0]);
    setName(info?.rowData[5]);
    setNew_Mobile(info?.rowData[6]);
    setMobile(info?.rowData[6]);
    setEmail(info?.rowData[7]);

    let site_name_arr = info?.rowData[8]?.split("%#%");
    let site_id_arr = info?.rowData[1]?.split(",");

    let iris_site_name_arr = info?.rowData[9]?.split("%#%");
    let iris_site_id_arr = info?.rowData[2]?.split(","); 
  

     let show_site = []
     if(info?.rowData[8] != "" && info?.rowData[8] != null && info?.rowData[8] != "null"){
    for (let i = 0; i < site_name_arr?.length; i++) {
       show_site.push({
           "label": site_name_arr[i],
           "value": site_id_arr[i]
       })
    }
  }
     let show_iris_site = []
     if(info?.rowData[9] != "" && info?.rowData[9] != null && info?.rowData[9] != "null"){
    for (let i = 0; i < iris_site_name_arr?.length; i++) {
      show_iris_site.push({
           "label": iris_site_name_arr[i],
           "value": iris_site_id_arr[i]
       })
    }
  }
    setSelected_Site(show_site);
    setSelected_Iris_Site(show_iris_site);

    setChecked_Audit(info?.rowData[3] == 1 ? true : false);
    setChecked_Sampling(info?.rowData[4] != 1 ? true : false);

    handleOpen4();
  };

  const handleActivate = (info) => {
    setIndividual_UserId(info?.rowData[0]);
    setConfirm_Msg("Are you sure want to Activate this user");
    handleOpen();
  };

  const handleDelete = (info) => {
    setCustomer_code(info?.rowData[12]);
    setMobile(info?.rowData[6]);
    handleOpen();
  };

  const handleSite = (new_data) => {
    setSite_Data(new_data);
    handleOpen5();
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_user_list();
      get_site_list();
      get_iris_site_list();
    }
  }, []);

  const columns = [
    {
      name: "id", // 0
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "site_id", // 1
      label: "site_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "master_site_client_id", // 2
      label: "master_site_client_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },

    {
      name: "is_audit", // 3
      label: "is_audit",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },

    {
      name: "is_sampling", // 4
      label: "is_sampling",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },

    {
      name: "site_user_name", // 5
      label: "Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          return <p style={{ padding: "10px 0px" }}>{row}</p>;
        },
      },
    },
    {
      name: "mobile_no", // 6
      label: "Mobile No",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: "80px", maxWidth: "80px" },
        }),
      },
    },

    {
      name: "email", // 7
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "site_name", // 8
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        display: is_sampling != "1"?true:false,
        setCellProps: () => ({
          style: { width: "300px", maxWidth: "auto" },
        }),
        customBodyRender: (row, data) => {
          if (row == "" || row == null) {
            return <>--</>;
          }
          let new_data = row?.split("%#%");
          if (new_data?.length > 3) {
            return (
              <>
                <p
                  onClick={() => handleSite(new_data)}
                  style={{ color: "#007cc3", cursor: "pointer" }}
                >
                  View Sites
                </p>
              </>
            );
          }
          return (
            <>
              {new_data?.map((ele) => (
                <>
                  <Chip
                    label={ele}
                    color="primary"
                    sx={{
                      marginLeft: "4px",
                      marginTop: "5px",
                      borderRadius: "7px",
                      height: "25px",
                    }}
                    variant="outlined"
                  />
                </>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "iris_site_name", // 9
      label: "IRIS Site Name",
      options: {
        filter: false,
        sort: false,
        display: is_audit == "1"?true:false,
        setCellProps: () => ({
          style: { width: "400px", maxWidth: "auto" },
        }),
        customBodyRender: (row, data) => {
          if (row == "" || row == null) {
            return <>--</>;
          }
          let new_data = row?.split("%#%");
          if (new_data?.length > 3) {
            return (
              <>
                <p
                  onClick={() => handleSite(new_data)}
                  style={{ color: "#007cc3", cursor: "pointer" }}
                >
                  View Sites
                </p>
              </>
            );
          }
          return (
            <>
              {new_data?.map((ele) => (
                <>
                  <Chip
                    label={ele}
                    color="primary"
                    sx={{
                      marginLeft: "4px",
                      marginTop: "5px",
                      borderRadius: "7px",
                      height: "25px",
                    }}
                    variant="outlined"
                  />
                </>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "profile_status", // 10
      label: "Status",
      options: {
        filter: false,
        sort: false,
        display:false,
        download:false,
      
      },
    },
    {
      name: "", // 11
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div style={{ padding: "7px" }}>
              {data?.rowData[10] == "Active" ? (
                <Tooltip title="Edit User" placement="left">
                  <EditIcon
                    style={{ color: "#007cc3", cursor: "pointer" }}
                    onClick={() => handleEdit(data)}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
                      <Tooltip title="Delete User" placement="left">
                  <DeleteIcon
                    style={{ color: "#007cc3", cursor: "pointer" }}
                    onClick={() => handleDelete(data)}
                  />
                </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "customer_code", // 12
      label: "customer_code",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{ paddingLeft: "5em", marginTop: "-40px" }}
      >
        {user_load ? (
          <div className="row" id={styles.loader_contain}>
            {" "}
            <img src={loader_img} alt="" />{" "}
          </div>
        ) : (
          <div className="row">
            <MUIDataTable
              title={
                <h4
                  style={{ fontSize: "18px", textAlign: "left" }}
                  id={styles.list_head}
                >
                  {/* <DescriptionOutlinedIcon
                  sx={{
                    marginRight: "10px",
                    fontSize: "27px",
                    marginTop: "-2px",
                    color: "#007cc3",
                  }}
                /> */}
                  User List
                </h4>
              }
              data={user_list}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                responsive: "standard",
                viewColumns: false,
                print: false,
                download: false,
                search: true,
                filter: false,
                sortOrder: {
                  name: "invoice_date",
                  direction: "desc",
                },
                rowsPerPageOptions: [10, 25, 50, 100],
                tableBodyMaxHeight: "58vh",
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
                customToolbar: () => {
                  return (
                    <div id={styles.filter_container}>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={handleOpen3}
                          style={{
                            width: "100px",
                            padding: "5px",
                            fontSize: "14px",
                          }}
                          variant="primary"
                        >
                          Add User
                        </button>
                      </div>
                    </div>
                  );
                },
                
              }}
            />
          </div>
        )}
      </div>
      <Confirm_user_status
        open={open}
        handleOpen={handleOpen}
        handleClose={handleDelete_Reset}
        status_load={status_load}
        func={delete_user}
      />

      <AddUpdate_user
        open={open3}
        handleOpen={handleOpen3}
        handleClose={handleModalRefresh}
        site_list={site_list}
        selected_site={selected_site}
        setSelected_Site={setSelected_Site}
        name={name}
        setName={setName}
        mobile={mobile}
        setMobile={setMobile}
        email={email}
        setEmail={setEmail}
        checked_sampling={checked_sampling}
        setChecked_Sampling={setChecked_Sampling}
        checked_audit={checked_audit}
        setChecked_Audit={setChecked_Audit}
        iris_site_list={iris_site_list}
        selected_iris_site={selected_iris_site}
        setSelected_Iris_Site={setSelected_Iris_Site}
        action_load={action_load}
        swal_msg={swal_msg}
        func={add_site_user}
        action_flag={"Add"}
      />
      <AddUpdate_user
        open={open4}
        handleOpen={handleOpen4}
        handleClose={handleModalRefresh}
        site_list={site_list}
        selected_site={selected_site}
        setSelected_Site={setSelected_Site}
        name={name}
        setName={setName}
        mobile={new_mobile}
        setMobile={setNew_Mobile}
        email={email}
        setEmail={setEmail}
        checked_sampling={checked_sampling}
        setChecked_Sampling={setChecked_Sampling}
        checked_audit={checked_audit}
        setChecked_Audit={setChecked_Audit}
        iris_site_list={iris_site_list}
        selected_iris_site={selected_iris_site}
        setSelected_Iris_Site={setSelected_Iris_Site}
        action_load={action_load}
        swal_msg={swal_msg}
        func={edit_site_user}
        action_flag={"Edit"}
      />
      <Site_pop_up
        open={open5}
        handleOpen={handleOpen5}
        handleClose={handleClose5}
        data={site_data}
      />
      <Footer />
    </>
  );
}

export default Add_user;
