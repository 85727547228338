import React, { useState, useEffect } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import { Button, TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import login_img from "../Media/login_img.png";
import bg_img from "../Media/login_new.jpg";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Menu, MenuItem, Tooltip } from "@mui/material";

function LoginPage({ base_url }) {
  const navigate = useNavigate();
  const storage = window.sessionStorage;
  const token = storage.getItem("token");

  const [mobileNo, setMobileNo] = useState("");
  const [show_inp, setShow_inp] = useState(false);
  const [show_timer, setShow_timer] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [show_btn, setShow_btn] = useState(false);
  const [session, setSession] = useState(null);
  const [load, setLoad] = useState(false);
  const [accData, setAccData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [year_show, setYear_Show] = useState(new Date());
  let info_arr = year_show?.toString()?.split(" ");

  const [send_btn, setSend_Btn] = useState(false);
  const [error_msg, setError_Msg] = useState("");

  //site useStates

  const [site_mobile, setSite_Mobile] = useState("");
  const [customer_code, setCustomer_Code] = useState("");
  const [site_otp, setSite_Otp] = useState("");
  const [site_error_msg, setSite_Error_Msg] = useState("");
  const [send_otp_btn, setSend_Otp_Btn] = useState(false);
  const [site_seconds, setSite_Seconds] = useState(30);
  const [show_site_timer, setShow_site_timer] = useState(false);
  const [show_site_otpInp, setShow_SiteOtpInp] = useState(false);
  const [site_login_load, setSite_Login_Load] = useState(false);

  const [alignment, setAlignment] = useState("customer");

  const handleChange = (event, newAlignment) => {
    if(newAlignment != null){
    setAlignment(newAlignment);
    setSite_Otp("");
    setSite_Mobile("");
    setCustomer_Code("");
    setSite_Error_Msg("");
    setShow_site_timer(false);
    setShow_SiteOtpInp(false);
    setShow_timer(false);
    setMobileNo("");
    setShow_inp(false);
    }
  };

  let timer;
  let timer2;

  //   const handleMobileChange = (e) => {
  //     if(e.target.value.length == 10){
  //         setMobileNo(e.target.value);
  //     }

  const start_timer = () => {
    setShow_timer(true);
    setShow_btn(false);
    setSeconds(30);
  };
  const start_site_timer = () => {
    setShow_site_timer(true);
    setSite_Seconds(30);
  };


  const sending_otp = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      send_otp();
    }
  };

  const swal_msg = (icon, msg) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      text: msg,
      customClass: {
        htmlContainer: "custom-html-container",
      },
    });
  };

  const send_otp = async (send_mobile) => {
    setSend_Btn(true);
    if (send_mobile?.length == 10) {
      const formData = new FormData();
      formData.append("mobile_no", `${send_mobile}`);
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "Multipart/form-data",
        },
      };
      axios
        .post(`${base_url}/send-customer-otp`, formData, config, {
          mode: "no-cors",
        })
        .then((res) => {
          setError_Msg("");
          if (res?.data?.message == "OTP was sent successfully.") {
            setOtp("");
            start_timer();
            setSend_Btn(false);

            setShow_inp(true);
            setIsDisabled(true);
            swal_msg("success", res?.data?.message);
          } else {
            setError_Msg(res?.data?.message);
            setSend_Btn(false);
            setShow_inp(false);
            setShow_timer(false);
            setShow_btn(true);
            setIsDisabled(false);
            swal_msg("error", res?.data?.message);
          }
        })
        .catch((err) => {
          setError_Msg("");
          setSend_Btn(false);

          setShow_timer(false);
          setShow_inp(false);
          setShow_btn(true);
          setIsDisabled(false);
          swal_msg("error", "Please Enter Valid Number.");
        });
    } else {
      setError_Msg("");
      swal_msg("error", "Please Enter Valid Number.");
      setSend_Btn(false);
    }
  };

  const handleOtp = (e) => {
    setMobileNo(e.target.value);
    // may be commented respect to previous design
    if (e.target.value.length == 10) {
      send_otp(e.target.value);
    }
  };

  const get_client_details = async (iris_id,User_mode) => {
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${User_mode == "site"?"1":"0"}`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  User_mode == "site"
                  ? "2"
                  : "0"
        })
      })
      let data = await res.json();
      storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
      storage.setItem("IS_CAPA_APPLICABLE",data?.data[0]?.IS_CAPA_APPLICABLE)
    } catch (error) {
    }
  };

  const get_accounts = async (mobileNo, token, profile) => {
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("login_mobile_no", `${mobileNo}`);
    axios
      .post(`${base_url}/get-customer-list`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        storage.setItem("count", res?.data?.customer?.length);
        storage.setItem("customer_id", res?.data?.customer[0]?.customer_id);
        storage.setItem("iris_name", res?.data?.customer[0]?.iris_site_name);
        storage.setItem(
          "customer_name",
          res?.data?.customer[0]?.capital_customer_name
        );
        storage.setItem("iris_id", profile?.master_client_id);
        storage.setItem("master_iris_client_id", profile?.master_client_id);
    

        // here is is_sampling != 1 means its true   (Customer Login)
 
        if (res?.data?.customer?.length == 1) {
          // if(profile?.master_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){         //vangoo id
          if (profile?.is_audit == "1") {
            get_client_details(profile?.master_client_id,"customer");
          }
          // }
          if (profile?.is_sampling != "1") {
            navigate("/dashboard");
          } else {
            storage.setItem("dashboard_type", "IRIS");
  
            if (
              profile?.master_client_id ==
              "f351d8b4-e52a-ea11-a961-000d3a584347"          //compass id
            ) {
              navigate("/compass_dashboard");
            } else {
              navigate("/schedule_list");
            }
          }
        } else {
          // if (profile?.is_sampling != "1") {
          // } else {
            // storage.setItem("dashboard_type", "IRIS");
            navigate("/accountsList");
            // if (
            //   profile?.master_client_id ==
            //   "f351d8b4-e52a-ea11-a961-000d3a584347"
            // ) {
            //   navigate("/compass_dashboard");
            // } else {
            //   navigate("/schedule_list");
            // }
          // }

        }
      })
      .catch((err) => {
        swal_msg("error", "Something went wrong.");
      });
  };

  const check_login = async (new_otp) => {
    setSend_Btn(true);
    const formData = new FormData();
    formData.append("mobile_no", `${mobileNo}`);
    formData.append("otp_no", `${new_otp}`);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "Multipart/form-data",
      },
    };
    axios
      .post(`${base_url}/customer-login`, formData, config, {
        mode: "no-cors",
      })
      .then((res) => {
        setSend_Btn(false);
        if (res?.data?.code == 200) {
          storage.setItem("login_type","client_login")
          let profile = res.data["user"].profile_details;
          let token = res.data["user"].token.access_token;
          storage.setItem("profile", JSON.stringify(profile));
          storage.setItem("token", JSON.stringify(token));
          swal_msg("success", res?.data?.message);
          storage.setItem("User_mode", alignment);
          storage.setItem("login_mobile_no", mobileNo);
          storage.setItem("is_sampling", profile?.is_sampling);
          storage.setItem("is_audit", profile?.is_audit);
          storage.setItem("dashboard_type", "HUB");
         
            if (mobileNo == "7304496662"){
              navigate("/zomato_dashboard");
            }
            else {
          get_accounts(mobileNo, token, profile);
            }
          // }
        } else {
          swal_msg("error", res?.data?.message);
        }
      })
      .catch((err) => {
        setSend_Btn(false);
        setOtp("");
        setIsDisabled(false);
        setShow_btn(true);
        setShow_timer(false);
        swal_msg("error", "Please Enter Valid OTP.");
      });
  };

  const check_login_site = async (newOtp) => {
    setSite_Login_Load(true);
    const formData = new FormData();
    formData.append("mobile_no", `${site_mobile}`);
    formData.append("otp_no", `${newOtp}`);
    formData.append("customer_code", `${customer_code}`);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "Multipart/form-data",
      },
    };
    axios
      .post(`${base_url}/customer-site-login`, formData, config, {
        mode: "no-cors",
      })
      .then((res) => {
        setSite_Login_Load(false);

        if (res.data?.code == 200) {
          let profile = res.data["user"].profile_details;
          let token = res.data["user"].token.access_token;
          storage.setItem("profile", JSON.stringify(profile));
          storage.setItem("token", JSON.stringify(token));
          storage.setItem("login_mobile_no", site_mobile);
          storage.setItem("customer_id", profile?.customer_id);
          storage.setItem("customer_code", customer_code);
          storage.setItem("customer_name", profile?.capital_customer_name);
          storage.setItem("site_name", profile?.site_user_name);
          storage.setItem("site_count", profile?.site_count);
          storage.setItem("is_sampling", profile?.is_sampling);
          storage.setItem("is_audit", profile?.is_audit);
          storage.setItem("User_mode", alignment);
          storage.setItem("iris_name", profile?.iris_site_name);
          storage.setItem(
            "master_iris_client_id",
            profile?.master_iris_client_id
          );
          swal_msg("success", res.data?.message);
          // here is is_sampling != 1 means its true

          storage.setItem("login_type","client_login")

          if (profile?.site_count == "1") {
            storage.setItem("site_id", profile?.site_id);
            storage.setItem("site_name", profile?.site_user_name);
            storage.setItem("iris_id", profile?.master_site_client_id);
            storage.setItem("dashboard_type", "HUB");
            // if(profile?.master_iris_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){          //vangoo id
            if (profile?.is_audit == "1") {
              get_client_details(profile?.master_site_client_id,"site");
            }
            // }
            if (profile?.is_sampling != "1") {
              navigate("/dashboard");
            } else {
              storage.setItem("dashboard_type", "IRIS");
              if (
                profile?.master_iris_client_id ==
                "f351d8b4-e52a-ea11-a961-000d3a584347"            //compass id
              ) {
                navigate("/compass_dashboard");
              } else {
                navigate("/schedule_list");
              }
            }
          } else {
            navigate("/user_site_list");
          }
        } else {
          swal_msg("error", res.data?.message);
        }

        // get_accounts(site_mobile,token);
      })
      .catch((err) => {
        setSite_Login_Load(false);
        setSite_Otp("");
        setShow_site_timer(false);
        swal_msg("error", "Please Enter Valid OTP.");
      });
  };

  const send_site_otp = async (mobileNo, token) => {
    setSend_Otp_Btn(true);
    setShow_site_timer(false);
    setShow_SiteOtpInp(false);
    setSite_Otp("");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("mobile_no", `${site_mobile}`);
    formData.append("customer_code", `${customer_code}`);
    axios
      .post(`${base_url}/send-site-customer-otp`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setSend_Otp_Btn(false);
        setSite_Error_Msg("");
        if (res?.data?.message == "OTP was sent successfully.") {
          setSite_Otp("");
          start_site_timer();
          setShow_SiteOtpInp(true);
          swal_msg("success", res?.data?.message);
        } else {
          setShow_SiteOtpInp(false);
          setSite_Error_Msg(res?.data?.message);
          swal_msg("error", res?.data?.message);
        }
      })
      .catch((err) => {
        setSite_Error_Msg("");
        setSend_Otp_Btn(false);
        swal_msg("error", "Something went wrong.");
      });
  };

  const handleSiteOtp = (e) => {
    setCustomer_Code(e.target.value.toUpperCase());
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (site_mobile?.length == 10) {
        send_site_otp();
      } else {
        swal_msg("error", "Please Enter Valid Number");
      }
    }
  };

  const handleLogin = (newValue) => {
    setOtp(newValue);
    if (newValue?.length == 6) {
      check_login(newValue);
    }
  };

  const handleLogin_site = (newValue) => {
    setSite_Otp(newValue);
    if (newValue?.length == 6) {
      check_login_site(newValue);
    }
  };

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    if (seconds == 0) {
      clearInterval(timer);
      setShow_timer(false);
      setShow_btn(true);
      setIsDisabled(false);
    }
    return () => clearInterval(timer);
  });

  useEffect(() => {
    timer2 = setInterval(() => {
      setSite_Seconds(site_seconds - 1);
    }, 1000);
    if (site_seconds == 0) {
      clearInterval(timer2);
      setShow_site_timer(false);
    }
    return () => clearInterval(timer2);
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" id={styles.top_bar}>
            <div id={styles.container}>
              <div className="row">
                <div className="col-xl-12 d-flex flex-row ">
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <PhoneIcon />
                    </div>

                    <a href="tel:02250647422">022 506 474 22</a>
                  </div>
                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_icons}>
                      <EmailIcon />
                    </div>

                    <a href="mailto:contact@equinoxlab.com">
                      contact@equinoxlab.com
                    </a>
                  </div>
                  <div
                    style={{ marginLeft: "auto" }}
                    id={styles.top_bar_contact_item}
                  ></div>

                  <div id={styles.top_bar_contact_item}>
                    <div id={styles.top_bar_social}>
                      <ul id={styles.social_icons}>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/equinox-labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Linkedin" placement="bottom">
                              <LinkedInIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.instagram.com/equinox_labs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Instagram" placement="bottom">
                              <InstagramIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.facebook.com/equinoxlabs/"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Facebook" placement="bottom">
                              <FacebookOutlinedIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://twitter.com/Equinox_Labs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Twitter" placement="bottom">
                              <XIcon />
                            </Tooltip>
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.youtube.com/@equinoxlabs"
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <Tooltip title="Youtube" placement="bottom">
                              <YouTubeIcon />
                            </Tooltip>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.equinoxLogo} col-12`}>
            {/* <img style={{marginTop:"10px"}} src={equinoxLogo} alt="" /> */}

            <img style={{ marginTop: "10px" }} src={hubcslogo} alt="" />
          </div>

          <div className="col-12">
            <div
              className="row"
              style={{ justifyContent: "space-around", alignItems: "center" }}
            >
              <div className="col-7">
                <img id={styles.login_pic} src={bg_img} alt="" />
              </div>

              <div className="col-4">
                <div
                  style={{ height: alignment == "site" ? "500px" : "400px" }}
                  id={styles.loginCard}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    id={styles.toggle_contain}
                    aria-label="Platform"
                    disabled={
                      send_btn || isDisabled || send_otp_btn || site_login_load
                    }
                  >
                    <ToggleButton value="customer">Customer Login</ToggleButton>
                    <ToggleButton value="site">Site Login</ToggleButton>
                  </ToggleButtonGroup>
                  <div className={styles.welcome_header}>
                    <h5>Welcome to Equinox Labs</h5>
                    <p className={styles.register_msg}>
                      If you are not a Equinox Client yet please&nbsp;
                      <a
                        href="https://www.equinoxlab.com/contact.php"
                        target="_blank"
                      >
                        click here
                      </a>
                      &nbsp;to register yourself, Our team will help you
                      on-board
                    </p>
                  </div>
                  {alignment == "customer" ? (
                    <div>
                      <TextField
                        id="standard-basic"
                        className={styles.number_inp}
                        label="Mobile Number"
                        type="number"
                        value={mobileNo}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        inputProps={{ maxLength: 10 }}
                        variant="standard"
                        sx={{
                          width: "80%",
                          fontSize: "18px",
                          color: "#44546A",
                          fontFamily: "Roboto",
                        }}
                        // onKeyUp={sending_otp}
                        onChange={handleOtp}
                        // may be commented respect to previous design
                        disabled={send_btn || isDisabled}
                        // disabled={isDisabled}
                      />
                      {error_msg?.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "90%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "5px",
                              textAlign: "right",
                              width: "80%",
                              color: "red",
                            }}
                          >
                            {error_msg}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* <button
                      className="btn btn-primary btn-sm"
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        wordWrap: "break-word",
                        width: "50%",
                        borderRadius: "24px",
                        marginTop: error_msg?.length > 0 ? "" : "3.5vh",
                      }}
                      onClick={send_otp}
                      disabled={send_btn || isDisabled}
                    >
                      Send OTP
                    </button> */}

                      {/* <div id={styles.btnContainer} style={{ marginTop: "1vh" }}>
                      <p
                        style={{ color: "#007cc3", cursor: "pointer" }}
                        onClick={send_otp}
                      >
                        {show_btn ? "Resend OTP" : ""}
                      </p>
                    </div> */}

                      {show_inp ? (
                        <>
                          <div>
                            <p className={styles.otpLabel}>Enter OTP</p>
                            <MuiOtpInput
                              value={otp}
                              length={6}
                              onChange={(newValue) => handleLogin(newValue)}
                              sx={{
                                width: "80%",
                                margin: "auto",

                                // marginTop: "30px",
                                gap: "10px",
                              }}
                              required
                              // onKeyUp={handleInput}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {show_timer ? (
                        <div>
                          <p
                            id={styles.timer}
                            style={{
                              color: seconds < 10 ? "red" : "green",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            <span>Resend in</span> {seconds} Seconds
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <button
                      className="btn btn-primary btn-sm"
                      style={{
                        color:"white",
                        fontSize:"14px",
                        fontFamily:"Roboto",
                        wordWrap: 'break-word',
                        width: "50%",
                        borderRadius:"24px",
                        marginTop: show_timer ? "0px" : "5vh",
                    
                      }}
                      onClick={check_login}
                      disabled= {otp?.length==6?false:true}
                    >
                      Log In
                    </button> */}
                    </div>
                  ) : (
                    <div>
                      <TextField
                        id="standard-basic"
                        className={styles.number_inp}
                        label="Mobile Number"
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        inputProps={{ maxLength: 10 }}
                        variant="standard"
                        sx={{
                          width: "80%",
                          fontSize: "18px",
                          color: "#44546A",
                          fontFamily: "Roboto",
                        }}
                        value={site_mobile}
                        disabled={send_otp_btn || site_login_load}
                        // onKeyUp={sending_otp}
                        onChange={(e) => setSite_Mobile(e.target.value)}
                      />
                      <TextField
                        id="standard-basic"
                        className={styles.code_inp}
                        label="Customer Code"
                        type="text"
                        variant="standard"
                        sx={{
                          width: "80%",
                          fontSize: "18px",
                          color: "#44546A",
                          fontFamily: "Roboto",
                        }}
                        value={customer_code}
                        onKeyUp={handleSiteOtp}
                        disabled={send_otp_btn || site_login_load}
                        onChange={(e) => setCustomer_Code(e.target.value.toUpperCase())}
                      />
                      {site_error_msg?.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "90%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "5px",
                              textAlign: "right",
                              width: "80%",
                              color: "red",
                            }}
                          >
                            {site_error_msg}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {show_site_otpInp ? (
                        <div>
                          <p className={styles.otpLabel}>Enter OTP</p>
                          <MuiOtpInput
                            length={6}
                            value={site_otp}
                            onChange={(newValue) => handleLogin_site(newValue)}
                            sx={{
                              width: "80%",
                              margin: "auto",

                              // marginTop: "30px",
                              gap: "10px",
                            }}
                            disabled={site_login_load}
                            required
                            // onKeyUp={handleInput}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {show_site_timer ? (
                        <div>
                          <p
                            id={styles.timer}
                            style={{
                              color: site_seconds < 10 ? "red" : "green",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            <span>Resend in</span> {site_seconds} Seconds
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <div className={styles.cardFooter}>
                    <p>
                      In case you face any issue while logging in the portal
                      please reach out to us on{" "}
                      <a href="mailto:contact@equinoxlab.com">
                        contact@equinoxlab.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <hr/> */}

          <div id={styles.footer_container}>
            <div className="col-12">
              <div className="row" id={styles.footerCopyright}>
                <div className="col-9">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                  <p id={styles.copyright}>
                    {/* Copyright : Equinox Labs Pvt ltd-{info_arr[3]} */}
                    Copyright © {info_arr[3]} Equinox Labs Private Limited
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
