import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import loader_img from "../Media/Business_chart.gif";
import styles from "../Styles/Modals.module.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import moment from "moment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function Schedule_timeline_popup({
  open,
  handleOpen,
  handleClose,
  data,
  load,
}) {
  const storage = window.sessionStorage;
  const profile = JSON.parse(storage.getItem("profile"));

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Schedules Timeline
              </h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button> */}
            </div>
            {load ? (
              <div>
                <img id={styles.loader} src={loader_img} />
              </div>
            ) : (
              <div className="modal-body mt-4" id={styles.service_modal}>


              {data[0]?.postponed_on_site_date_time == null && data[0]?.postponed_by_scheduler_date_time == null?  <Box sx={{ width: "100%" }}>
                  <Stepper
                    activeStep={
                        data[0]?.scheduled_date_time == null?0:
                        data[0]?.enroute_date_time == null?1:
                        data[0]?.reach_date_time == null ?2:
                        data[0]?.collected_date_time == null ?3:
                        4
                
                    }
                    alternativeLabel
                  >
                    {/* {steps.map((label,index) => ( */}
                    <Step >
                      <StepLabel      sx={{
                        "& .MuiStepIcon-root": {
                          color: data[0]?.scheduled_date_time != null ? "#007cc3" : "gray", // Highlight only completed steps
                        },
                        "& .MuiStepIcon-root.Mui-active": {
                          color: "grey", // Active step in a different color (optional)
                        },
                        "& .MuiStepLabel-label": {
                          color: data[0]?.scheduled_date_time != null ? "black" : "gray", // Completed = black, Not completed = gray
                        },
                      }}>
                        <Box textAlign="center">Scheduled</Box>
                        {data[0]?.scheduled_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.scheduled_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                        {/* {index < steps.length - 1 && <Divider sx={{ mt: 1, bgcolor: "black" }} />} */}
                      </StepLabel>
                      {/* <StepLabel>{label}</StepLabel> */}
                    </Step>
                    <Step>
                      <StepLabel
                        sx={{
                          "& .MuiStepIcon-root": {
                            color: data[0]?.enroute_date_time != null ? "#007cc3" : "gray", // Highlight only completed steps
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "grey", // Active step in a different color (optional)
                          },
                          "& .MuiStepLabel-label": {
                            color: data[0]?.enroute_date_time != null ? "black" : "gray", // Completed = black, Not completed = gray
                          },
                        }}
                      >
                        <Box textAlign="center">Enroute</Box>
                        {data[0]?.enroute_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.enroute_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel
                        sx={{
                          "& .MuiStepIcon-root": {
                            color: data[0]?.reach_date_time != null ? "#007cc3" : "gray", // Highlight only completed steps
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "grey", // Active step in a different color (optional)
                          },
                          "& .MuiStepLabel-label": {
                            color: data[0]?.reach_date_time != null ? "black" : "gray", // Completed = black, Not completed = gray
                          },
                        }}
                      >
                        <Box textAlign="center">Reached</Box>
                        {data[0]?.reach_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.reach_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel
                        sx={{
                          "& .MuiStepIcon-root": {
                            color: data[0]?.collected_date_time != null ? "#007cc3" : "gray", // Highlight only completed steps
                          },
                          "& .MuiStepIcon-root.Mui-active": {
                            color: "grey", // Active step in a different color (optional)
                          },
                          "& .MuiStepLabel-label": {
                            color: data[0]?.collected_date_time != null ? "black" : "gray", // Completed = black, Not completed = gray
                          },
                        }}
                      >
                        <Box textAlign="center">Collected</Box>
                        {data[0]?.collected_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.collected_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    {/* ))} */}
                  </Stepper>
                </Box>:<></>}

                {/* postponed by scheduler  */}

                {data[0]?.postponed_by_scheduler_date_time != null?<Box sx={{ width: "100%" }}>
                  <Stepper
                    activeStep={
     1
                            }
                    alternativeLabel
                  >
                    {/* {steps.map((label,index) => ( */}
                    <Step>
                      <StepLabel
                  
                      >
                        <Box textAlign="center">Scheduled</Box>
                        {data[0]?.scheduled_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.scheduled_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                        {/* {index < steps.length - 1 && <Divider sx={{ mt: 1, bgcolor: "black" }} />} */}
                      </StepLabel>
                      {/* <StepLabel>{label}</StepLabel> */}
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Postponed by scheduler</Box>
                        {data[0]?.postponed_by_scheduler_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.postponed_by_scheduler_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Enroute</Box>
                        {data[0]?.enroute_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.enroute_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Reached</Box>
                        {data[0]?.reach_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.reach_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Collected</Box>
                        {data[0]?.collected_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.collected_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    {/* ))} */}
                  </Stepper>
                </Box>:<></>}

{/* postponed on site  */}

                {data[0]?.postponed_on_site_date_time != null ?<Box sx={{ width: "100%" }}>
                  <Stepper
                    activeStep={
   3
                            }
                    alternativeLabel
                  >
                    {/* {steps.map((label,index) => ( */}
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Scheduled</Box>
                        {data[0]?.scheduled_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.scheduled_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                        {/* {index < steps.length - 1 && <Divider sx={{ mt: 1, bgcolor: "black" }} />} */}
                      </StepLabel>
                      {/* <StepLabel>{label}</StepLabel> */}
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Enroute</Box>
                        {data[0]?.enroute_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.enroute_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Reached</Box>
                        {data[0]?.reach_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.reach_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Postponed on site</Box>
                        {data[0]?.postponed_on_site_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.postponed_on_site_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Box textAlign="center">Collected</Box>
                        {data[0]?.collected_date_time == null ? (
                          <>--</>
                        ) : (
                          <p>
                            {moment(data[0]?.collected_date_time).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          </p>
                        )}
                      </StepLabel>
                    </Step>
                    {/* ))} */}
                  </Stepper>
                </Box>:<></>}
              </div>
            )}
            <div className="modal-footer mt-4">
              <button
                type="button"
                className="btn btn-secondary"
                // data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Schedule_timeline_popup;
