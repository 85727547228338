import React from "react";
import styles from "../Styles/Modals.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import loader_img from "../Media/Business_chart.gif";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  zIndex: "9999",
};
const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    width: "100%",
    textAlign: "left",
    fontSize: "14px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textAlign: "left",
      fontSize: "12px",
    };
  },
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    textAlign: "left",
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: "60px",
    overflowY: "auto",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Set this to the desired z-index
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

function AddUpdate_user({
  open,
  handleOpen,
  handleClose,
  site_list,
  selected_site,
  setSelected_Site,
  name,
  setName,
  mobile,
  setMobile,
  email,
  setEmail,
  checked_sampling,
  setChecked_Sampling,
  checked_audit,
  setChecked_Audit,
  iris_site_list,
  selected_iris_site,
  setSelected_Iris_Site,
  action_load,
  swal_msg,
  func,
  action_flag,
}) {
  const storage = window.sessionStorage;
  let is_audit = storage.getItem("is_audit")
  let is_sampling = storage.getItem("is_sampling");
 
  const handleChangeSampling = (event) => {
    setChecked_Sampling(event.target.checked);
    if(event.target.checked == false){
      setSelected_Site([])
    }
  };
  const handleChangeAudit = (event) => {
    setChecked_Audit(event.target.checked);
    if(event.target.checked == false){
      setSelected_Iris_Site([])
    }
  };

  function isValidEmail(val) {
    return /\S+@\S+\.\S+/.test(val);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!checked_sampling && !checked_audit){
      swal_msg("error", "Please Select anyone option");
    }else{
    if (isValidEmail(email) && mobile.length == 10) {
      func();
    } else {
      swal_msg("error", mobile.length != 10?"Please Enter Valid Mobile Number":"Please Enter Valid Email");
    }
  }
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} id={styles.add_user_pop_up}>
        <div className="modal-content">
          <div className="modal-header" style={{ marginBottom: "20px" }}>
            {action_flag == "Add" ? (
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ color: "black", fontWeight: "400" }}
              >
                Add User
              </h1>
            ) : (
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ color: "black", fontWeight: "400" }}
              >
                Update User
              </h1>
            )}
            <CloseIcon id={styles.close_icon} onClick={handleClose} />
          </div>
          {action_load ? (
            <div className="row" id={styles.loader_contain}>
              {" "}
              <img src={loader_img} alt="" />{" "}
            </div>
          ) : (
            <div className="modal-body" id={styles.User_body}>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="">
                      Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="">
                      Mobile<span>*</span>
                    </label>
                    <input
                      type="number"
                      value={mobile}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="">
                      Email<span>*</span> <span></span> (Credentials will be sent via mail.)
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-6"></div>
                </div>

                <div className="row mt-2" id={styles.allowedFlagContainer}>
                {is_sampling != "1"?  <div className="col-sm-12 col-md-6">
                {/* {is_sampling != "1"?  <div className="col-sm-12 col-md-6"> */}
                    <Checkbox
                      checked={checked_sampling}
                      onChange={handleChangeSampling}
                      inputProps={{ "aria-label": "controlled" }}
                      // required
                    />
                    <p>Sampling</p>
                  </div>:<></>}
                  {is_audit == "1"?<div className="col-sm-12 col-md-6">
                  {/* {is_audit == "1"?<div className="col-sm-12 col-md-6"> */}
                    <Checkbox
                      checked={checked_audit}
                      onChange={handleChangeAudit}
                      inputProps={{ "aria-label": "controlled" }}
                      // required
                    />
                    <p>Audit</p>
                  </div>:<></>}
                </div>

                <div className="row mt-2">
                  {checked_sampling && is_sampling != "1" ? (
                    <div className="col-sm-12 col-md-6">
                      <label htmlFor="">
                      Sampling Site List<span>*</span>
                      </label>
                        <Select
                          isMulti
                          name="label"
                          options={site_list}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={customStyles}
                          isClearable={true}
                          value={selected_site}
                          onChange={(e) => setSelected_Site(e)}
                          // closeMenuOnSelect={false}
                          maxMenuHeight="180px"
                          placeholder="Select"
                          menuPortalTarget={document.body}
                          required
                        />
                    
                    </div>
                  ) : (
                    is_sampling == "1"?<></>:<div className="col-sm-12 col-md-6"></div>
                  )}

                  {checked_audit && is_audit == "1" ? (
                    <div className="col-sm-12 col-md-6">
                      <label htmlFor="">
                      Audit Site List<span>*</span>
                      </label>
                      <Select
                      isMulti
                        name="label"
                        options={iris_site_list}
                        className="basic-select"
                        classNamePrefix="select"
                        styles={customStyles}
                        isClearable={true}
                        value={selected_iris_site}
                        onChange={(e) => setSelected_Iris_Site(e)}
                        // closeMenuOnSelect={false}
                        maxMenuHeight="180px"
                        placeholder="Select"
                        menuPortalTarget={document.body}
                        required
                      />
                    </div>
                  ) : (
                    is_audit != "1"?<></>:<div className="col-sm-12 col-md-6"></div>
                  )}
                </div>

                <div className="row mt-4">
                  <button
                    className="btn btn-primary"
                    style={{
                      width: "100px",
                      padding: "5px",
                      fontSize: "14px",
                      margin: "auto",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default AddUpdate_user;
