import React, { useEffect, useState } from "react";
import styles from "../Styles/Dashboard.module.css";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Tooltip } from "@mui/material";

function Footer() {
    const storage = window.sessionStorage;
    let support_details = JSON?.parse(storage.getItem("support_details"));
    
    
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");
  
    // load usestates
  
  return (
    <>
      <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                    <p id={styles.copyright}>

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
                  {/* </div> */}
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <h6><span>Key Account Executive</span> : {support_details?.planner_name}</h6>
                       {/* <h6><span>KAE</span> : {support_details?.planner_name}</h6> */}
                       <h6><span><WhatsAppIcon/></span> : {support_details?.planner_number}</h6>
                       <h6><span><EmailIcon/></span> : {support_details?.planner_email}</h6>
                    </div>
                    <div>
                       <h6><span>Business Development Executive</span> : {support_details?.salesperson_name}</h6>
                       {/* <h6><span>BDE</span> : {support_details?.salesperson_name}</h6> */}
                       <h6><span><WhatsAppIcon/></span> : {support_details?.salesperson_number}</h6>
                       <h6><span><EmailIcon/></span> : {support_details?.salesperson_email}</h6>
                    </div>
                </div>
              </div>

          </div>
    </>
  );
}

export default Footer;
