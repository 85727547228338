import React, { useEffect, useState } from "react";
import MenuAppBar from "../Components/Header";
import styles from "../Styles/Dashboard.module.css";
import axios from "axios";
import Drawer_customer from "../Components/Drawer";
import Invoice_tbl from "../Components/Invoice_tbl";
import loader_img from "../Media/Business_chart.gif"
import moment from "moment";
import Footer from "../Components/footer";

function Payments({base_url}) {
  const storage = window.sessionStorage;
  let profile = JSON?.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let User_mode = storage.getItem("User_mode");
  let iris_logo_image = storage.getItem("iris_logo_image")
  let master_iris_client_id = storage.getItem("master_iris_client_id")
  
  const [payments_count, setPayments_Count] = useState([]);

  const [transaction_data, setTransaction_Data] = useState([]);
  const [invoice_data, setInvoice_Data] = useState([]);

  const [selected_transaction, setSelected_Transaction] = useState("");
  
  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");


  // load usestates

  const [count_load,setCount_Load] = useState(false)
  const [transaction_load,setTransaction_Load] = useState(false)
  const [invoice_load,setInvoice_Load] = useState(false)

  const get_payments_count = () => {
    setCount_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("customer_id", `${customer_id}`);
    // if (site_id != "" && site_id != null) {
    //   formData.append("site_id", site_id);
    // }
    axios({
      url: `${base_url}/transaction-dashboard-count`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setCount_Load(false);
        // setError(false);
        setPayments_Count(res.data?.data[0]);
      })
      .catch((err) => {
        setCount_Load(false)
        // setError(true);
        
      });
  };

  const get_transaction_list = () => {
    setTransaction_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("customer_id", `${customer_id}`);
    // if (site_id != "" && site_id != null) {
    //   formData.append("site_id", site_id);
    // }
    axios({
      url: `${base_url}/transaction-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setTransaction_Load(false);
        // setError(false);

        setTransaction_Data(res.data?.data);
        get_invoice_list(res.data?.data[0]?.id);
      })
      .catch((err) => {
        setTransaction_Load(false);
        
      });
  };

  const get_invoice_list = (val) => {
    setInvoice_Load(true);
    setSelected_Transaction(val);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("transaction_id", `${val}`);
    formData.append("customer_id", `${customer_id}`);
    // if (site_id != "" && site_id != null) {
    //   formData.append("site_id", site_id);
    // }
    //   formData.append("customer_id", `${customer_id}`);
    axios({
      url: `${base_url}/invoice-list-by-transaction`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setInvoice_Load(false);
        // setError(false);

        setInvoice_Data(res.data?.data);
      })
      .catch((err) => {
        setInvoice_Load(false);
        
      });
  };

  useEffect(() => {
    if (token != "" && token != null) {
      if(User_mode == "customer"){
        storage.setItem("site_id","")
        }
      get_payments_count();
      get_transaction_list();
    }
  }, []);

  return (
    <>
      {/* <MenuAppBar /> */}
      <Drawer_customer />
     {(count_load||transaction_load)?<div>
      <img id={styles.loader} src={loader_img}/>
     </div>:<div className="container-fluid" style={{ paddingLeft: "5em" }}>
         {master_iris_client_id == "a95226c4-9917-4db8-9009-0481155bae76"?  <img src={iris_logo_image} alt="text" id={styles.iris_logo_img} />:<></>}
        <div
          className="row"
          style={{ width: "98%", textAlign: "left", marginLeft: "2vh" }}
        >
          <div className="col-sm-12 col-md-2">
            <p className={styles.tabsHeading}>Not yet Due</p>
            <h4 className={styles.tabsCount}>
              INR{" "}
              {payments_count?.Not_Yet_Due == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(
                    payments_count?.Not_Yet_Due
                  )}
            </h4>
          </div>
          <div className="col-sm-12 col-md-2">
            <p className={styles.tabsHeading}>Due in next 1 week</p>
            <h4 className={styles.tabsCount}>
              INR{" "}
              {payments_count?.Due_Within_7_Days == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(
                    payments_count?.Due_Within_7_Days
                  )}
            </h4>
          </div>
          <div className="col-sm-12 col-md-2">
            <p className={styles.tabsHeading}>UnAdjusted Payments</p>
            <h4 className={styles.tabsCount}>
              INR{" "}
              {payments_count?.Unadjusted_Payment == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(
                    payments_count?.Unadjusted_Payment
                  )}
            </h4>
          </div>
          <div className="col-sm-12 col-md-2">
            <p className={styles.tabsHeading}>Adjusted Payments</p>
            <h4 className={styles.tabsCount}>
              INR{" "}
              {payments_count?.Adjusted_Payment == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(
                    payments_count?.Adjusted_Payment
                  )}
            </h4>
          </div>
          <div className="col-sm-12 col-md-2">
            <p className={styles.tabsHeading}>Overdue Bills</p>
            <h4 className={styles.tabsCount} style={{ color: "red" }}>
              INR{" "}
              {payments_count?.Overdue_Bill == null
                ? 0
                : new Intl.NumberFormat("en-IN").format(
                    payments_count?.Overdue_Bill
                  )}
            </h4>
          </div>
        </div>

        <div className="row" style={{ marginTop: "2vh" }}>
          <div className="col-sm-12 col-md-2" id={styles.leftPart}>
            {transaction_data?.map((ele) => (
              <div
                className={styles.leftUnit}
                style={{
                  backgroundColor:
                    selected_transaction == ele?.id ? "#DDDDDD" : "",
                }}
                onClick={() => get_invoice_list(ele?.id)}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={styles.transAmount}>
                    <span id={styles.amount}>Amount:</span>
                    <span id={styles.amt_val}>
                      {" "}
                      {ele?.amount != null && ele?.amount != ""
                        ? new Intl.NumberFormat("en-IN").format(ele?.amount)
                        : 0}{" "}
                      INR
                    </span>
                  </p>
                  <div
                    style={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor:
                        ele?.status == "Adjusted"
                          ? "green"
                          : ele?.status == "Partially adjusted"
                          ? "yellow"
                          : "red",
                    }}
                  >
                    {" "}
                  </div>
                </div>
                <p id={styles.trans_date}>
                  Transaction Date:{" "}
                  {ele?.date != null && ele?.date != ""
                    ? moment(ele?.date).format("DD MMM YYYY")
                    : ""}
                </p>
              </div>
            ))}
            {/* <div className={styles.leftUnit}>
                    <p className={styles.transAmount}>
                        <span id={styles.amount}>Amount:</span> 
                        <span id={styles.amt_val}>1,50,000 INR</span></p>
                    <p id={styles.trans_date}>Transaction Date: 12 Nov 23</p>
                </div>
                <div className={styles.leftUnit}>
                    <p className={styles.transAmount}>
                        <span id={styles.amount}>Amount:</span> 
                        <span id={styles.amt_val}>1,50,000 INR</span></p>
                    <p id={styles.trans_date}>Transaction Date: 12 Nov 23</p>
                </div>
                <div className={styles.leftUnit}>
                    <p className={styles.transAmount}>
                        <span id={styles.amount}>Amount:</span> 
                        <span id={styles.amt_val}>1,50,000 INR</span></p>
                    <p id={styles.trans_date}>Transaction Date: 12 Nov 23</p>
                </div>
                <div className={styles.leftUnit}>
                    <p className={styles.transAmount}>
                        <span id={styles.amount}>Amount:</span> 
                        <span id={styles.amt_val}>1,50,000 INR</span></p>
                    <p id={styles.trans_date}>Transaction Date: 12 Nov 23</p>
                </div>
                <div className={styles.leftUnit}>
                    <p className={styles.transAmount}>
                        <span id={styles.amount}>Amount:</span> 
                        <span id={styles.amt_val}>1,50,000 INR</span></p>
                    <p id={styles.trans_date}>Transaction Date: 12 Nov 23</p>
                </div> */}
          </div>

          <div className="col-sm-12 col-md-10" id={styles.rightPart}>
           {invoice_load?<img src={loader_img} id={styles.loader} />: <Invoice_tbl data={invoice_data} />}
          </div>
        </div>
      </div>}
      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
     
                    <p id={styles.copyright}>
    

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
        
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}

          <Footer/>
    </>
  );
}

export default Payments;

{
  /* <div >
<h2>20,000 INR</h2>
<h6 >Un-Adjusted Amount</h6>
</div>
<div style={{textAlign:"left", marginTop:"2vh"}}>
<h6 
// style={{fontWeight:"bold", display:"flex", alignItems:"center", gap:"5px" }}
>Invoice Adjusted: 
<span > 0 INR</span>
</h6>
</div>
<div style={{textAlign:"left", marginTop:"2vh"}}>
<button id={styles.adjust_btn} className='btn btn-primary btn-sm' style={{width:"25vh"}}>
    Adjust invoices
</button>
</div> */
}
