import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard'
import Sites from '../Pages/Sites'
import Purchase_order from '../Pages/Purchase_order'
import Invoice from '../Pages/Invoice'
import LoginPage from "../Pages/LoginPage"
import AccountsList from '../Pages/AccountsList'
import Download_report from '../Pages/Download_report'
import Payments from '../Pages/Payments'
import Site_Page from '../Pages/Site_Page'
import Query_list from '../Pages/Query_list'
import Service_on_customer from '../Pages/Service_on_customer'
import Audit_Dashboard from '../Pages/Audit_Dashboard'
import Schedules_List from '../Pages/Schedules_List'
import Audit_Section_Dashboard from '../Pages/Audit_Section_Dashboard'
import Leads_audit_data from '../Pages/Leads_audit_data'
import Monthly_summary from '../Pages/Monthly_summary'
import Compass_dashboard from '../Pages/Compass_dashboard'
import Schedule_List_v2 from '../Pages/Schedule_List_v2'
import Audit_Dashboard_v2 from '../Pages/Audit_Dashboard_v2'
import Capa_dashboard from '../Pages/Capa_dashboard'
import Audit_Section_report_v2 from '../Pages/Audit_Section_report_v2'
import User_site_list from '../Pages/User_site_list'
import Add_user from '../Pages/Add_user'
import Employee_login from '../Pages/Employee_login'
import Emp_customer_list from '../Pages/Emp_customer_list'

function Allroutes() {
  let base_url = "https://hub.equinoxlab.com/api"
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage base_url={base_url}/> }/>
        {/* <Route path="/customerList" element={<CustomersList base_url={base_url} /> }/> */}
        <Route path="/dashboard" element={<Dashboard base_url={base_url} />}/>
        <Route path="/sites" element={<Sites base_url={base_url}/>}/>
        <Route path="/purchase_order" element={<Purchase_order base_url={base_url}/>}/>
        <Route path="/invoices" element={<Invoice base_url={base_url}/>}/>
        <Route path="/accountsList" element={<AccountsList base_url={base_url} />} />
        <Route path="/download_report" element={<Download_report base_url={base_url} />} />
        <Route path="/service_avail" element={<Service_on_customer base_url={base_url} />} />
        <Route path="/payments" element={<Payments base_url={base_url} />} />
        <Route path="/site_page" element={<Site_Page base_url={base_url} />} />
        <Route path="/query_list" element={<Query_list base_url={base_url} />} />
        <Route path="/user_site_list" element={<User_site_list base_url={base_url} />} />
        <Route path="/user_list" element={<Add_user base_url={base_url} />} />
        <Route path="/employee_login" element={<Employee_login base_url={base_url} />} />
        <Route path="/employee_customer_list" element={<Emp_customer_list base_url={base_url} />} />

{/* external dashboard changes */}

        <Route path="/audit_dashboard" element={<Audit_Dashboard base_url={base_url} />} />
        <Route path="/audit_dashboard_v2" element={<Audit_Dashboard_v2 base_url={base_url} />} />
        <Route path="/section_report" element={<Audit_Section_Dashboard base_url={base_url} />} />
        {/* <Route path="/section_report" element={<Section_report base_url={base_url} />} /> */}
        <Route path="/schedules" element={<Schedules_List base_url={base_url} />} />
        <Route path="/schedule_list" element={<Schedule_List_v2 base_url={base_url} />} />
        <Route path="/zomato_dashboard" element={<Leads_audit_data base_url={base_url} />} />
        <Route path="/monthly_summary" element={<Monthly_summary base_url={base_url} />} />
        <Route path="/compass_dashboard" element={<Compass_dashboard base_url={base_url} />} />
        <Route path="/capa_dashboard" element={<Capa_dashboard base_url={base_url} />} />
        <Route path="/audit_section_report_v2" element={<Audit_Section_report_v2 base_url={base_url} />} />
      </Routes>
    </div>
  )
}

export default Allroutes