import React from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import loader_img from "../Media/Business_chart.gif";
import styles from "../Styles/Modals.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function Confirm_user_status({open,handleOpen,handleClose,status_load,func}) {
    const storage = window.sessionStorage;

    const handleClick = ()=>{
       func();
    }

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <div className="modal-content">
        <div className="modal-header">
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button> */}
        </div>
      {status_load?<div className="row" id={styles.loader_contain}>  <img  src={loader_img} alt="" /> </div>:
 <div className="modal-body mt-4">
 <h3 id={styles.confirm_txt}>Are you sure want to Delete this user?</h3>
        
        <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={handleClick} class="btn btn-primary">
                Yes
              </button>
              <button onClick={handleClose} class="btn btn-secondary">
                No
              </button>
            </div>
            </div>}
      </div>
    </Box>
  </Modal>
  )
}

export default Confirm_user_status
