import React, { useEffect, useState } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Iris_footer from "../Components/Iris_footer";

function User_site_list({base_url}) {
    const storage = window.sessionStorage;
    let customer_id = storage.getItem("customer_id")
    let profile = JSON.parse(storage.getItem("profile"))
    let mobile_no = storage.getItem("login_mobile_no")
    let token = storage.getItem("token")
    const site_count = storage.getItem("site_count");
    let master_iris_client_id = storage.getItem("master_iris_client_id");

    let is_sampling = storage.getItem("is_sampling");
    let is_audit = storage.getItem("is_audit");

    const [sampling_site_list,setSampling_Site_List] = useState([]);
    const [audit_site_list,setAudit_Site_List] = useState([]);
    const [site_list,setSite_List] = useState([]);
    const [search_term, setSearch_Term] = useState("");

    const [audit_flag,setAudit_Flag] = useState(false);
    const [sampling_flag,setSampling_Flag] = useState(false)

    const [load,setLoad] = useState(false);

    
  const get_client_details = async (iris_id,User_mode) => {
    let site_type=storage.getItem("site_type")
    try {
      // let res = await fetch(
      //   `https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V1/${iris_id}/${site_type == "all"?"2":User_mode == "site"?"1":"0"}`
      // );
      let res = await fetch(`https://irisauditor.com/api_v1/Audit_Timeline.svc/get_CLIENT_DETAILS_V2`,{
        method:"POST",
        headers: {"Content-type":"Application/Json"},
        body: JSON.stringify({
          ID:iris_id,
          LOGIN_TYPE:  
                   User_mode == "site"
                  ? "2"
                  : "0"
        })
      })
      let data = await res.json();
      storage.setItem("iris_logo_image",data?.data[0]?.LOGO_IMG)
      storage.setItem("IS_CAPA_APPLICABLE",data?.data[0]?.IS_CAPA_APPLICABLE)
    } catch (error) {
    }
  };

    const handleClick_Sampling = (info)=>{
        storage.setItem("site_id",info?.site_id)
        storage.setItem("iris_id",info?.master_site_client_id)
        storage.setItem("site_name",info?.site_name)
        storage.setItem("is_audit",info?.is_audit)
        storage.setItem("is_sampling",info?.is_hub)
        storage.setItem("iris_name",info?.iris_site_name)
        storage.setItem("dashboard_type","HUB")
    navigate("/dashboard")
    }

    const handleClick_Audits = (info)=>{
      storage.setItem("site_id",info?.site_id)
        storage.setItem("iris_id",info?.master_site_client_id)
        storage.setItem("site_name",info?.site_name)
        storage.setItem("is_audit",info?.is_audit)
        storage.setItem("is_sampling",info?.is_hub)
        storage.setItem("iris_name",info?.iris_site_name)
        storage.setItem("dashboard_type","IRIS")
        if(master_iris_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347"){     //compass id
            navigate("/compass_dashboard")
        }else{
          navigate("/schedule_list")
        }
 
    }

    const handleClick = (info)=>{
      if(info == "all"){
        storage.setItem("site_id",sampling_site_list?.toString())
        storage.setItem("iris_id",audit_site_list?.toString()) 
        storage.setItem("is_audit",audit_flag?"1":"0")
        storage.setItem("is_sampling",sampling_flag?0:"1")
        storage.setItem("site_type","all")
        if(audit_flag){
          get_client_details(audit_site_list?.toString(),"site");
        }

        if(sampling_flag){
          navigate("/dashboard")
        }else{
          if(master_iris_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347"){    //compass
            navigate("/compass_dashboard")
          }else{
          navigate("/schedule_list")
          }
        }

      }else{
        storage.setItem("site_type","")
      storage.setItem("site_id",info?.site_id)
      storage.setItem("iris_id",info?.master_site_client_id)
      storage.setItem("site_name",info?.site_name)
      storage.setItem("is_audit",info?.is_audit)
      storage.setItem("is_sampling",info?.is_hub)
      storage.setItem("iris_name",info?.iris_site_name)
      // if(master_iris_client_id == "a95226c4-9917-4db8-9009-0481155bae76"){   // vangoo id
      if(info?.is_audit == "1"){
        get_client_details(info?.master_site_client_id,"site");
      }
      // }
      if(info?.is_hub != "1"){
        navigate("/dashboard")
        storage.setItem("dashboard_type","HUB")
      }else{
        storage.setItem("dashboard_type","IRIS")
        if(master_iris_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347"){      // compass id 
          navigate("/compass_dashboard")
        }else{
        navigate("/schedule_list")
        }
      }
    }
      // if(master_iris_client_id == "f351d8b4-e52a-ea11-a961-000d3a584347"){
      //     navigate("/compass_dashboard")
      // }else{
      //   navigate("/schedule_list")
      // }
    }

    const navigate= useNavigate();

      const swal_msg = (icon, msg) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: icon,
          text: msg,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      };
      
    const get_site_list = async () => {
        setLoad(true)
        axios.defaults.headers.common = {
          Authorization: "Bearer " + token?.replace('"', ""),
        };
        const formData = new FormData();
        formData.append("mobile_number", `${mobile_no}`);
        formData.append("customer_id", `${customer_id}`);
          formData.append("search", `${search_term}`);
        axios
          .post(`${base_url}/get-site-list-with-customercode`, formData, {
          // .post(`${base_url}/get-site-list-with-custcode`, formData, {
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "Multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((res) => {
            setLoad(false)
             if(res?.data?.code == "200"){
              let sampling_arr = [];
              let audit_arr = [];
              setSite_List(res?.data?.data)
              for(let i=0; i<res?.data?.data?.length; i++){
                if(res.data.data[i]?.site_id != null){
                  sampling_arr.push(res.data.data[i]?.site_id)
                }
                if(res.data.data[i]?.master_site_client_id != null){
                  audit_arr?.push(res.data.data[i]?.master_site_client_id)
                }
              if(res.data.data[i]?.is_hub != "1"){
                setSampling_Flag(true)
              }
              if(res.data.data[i]?.is_audit == "1"){
               setAudit_Flag(true)
              }

              }
            setSampling_Site_List(sampling_arr);
            setAudit_Site_List(audit_arr)
             }else{
                swal_msg(res?.data?.Details == "Site not found"?"info":"error",res?.data?.Details)
             }
          })
          .catch((err) => {
            setLoad(false)
            swal_msg("error","Something went wrong.")
          });
      };
      
      const handleSearch = (e) => {
        e.preventDefault();
        get_site_list();
      };

    useEffect(()=>{
      if (token != "" && token != null) {
     get_site_list();
      }
    },[])
  return (
    <>
    <div className="container-fluid p-4">
          <div className="row">
          <div className={`${styles.equinoxLogo2} col-md-6 col-sm-12`}>
            <img src={equinoxLogo} alt="" />
          </div>

          <div className={`${styles.customerhubLogo} col-md-6 col-sm-12`}>
            <img src={hubcslogo} alt="" />
          </div>

        </div>
        <div style={{marginBottom:"3vh"}}>
          {/* <h3 style={{color:"#007cc3"}}>Welcome {profile?.capital_customer_name}</h3> */}
          <h3 style={{color:"#007cc3"}}>Welcome !!</h3>
          {/* {
          (site_count == 0 && site_list?.length == 0)?<h6 className="h6 mt-2">There is no Site registered on your number</h6>
          :
          site_count == 1?  <h6 className="h6 mt-2">There is {site_count} Site registered on your number, Please Select
          an Site to process further.</h6>:<h6 className="h6 mt-2">There are {site_count} Sites registered on your number, Please Select
          an Site to process further.</h6>} */}

        </div>
        {load ? (
          // <Account_load />
          <>LOADING....</>
        ) : (
          <>
            {" "}
            {site_list?.length == 0?<h6 id={styles.site_header} className="h6 mt-1">There is no Site registered on your number</h6>
          :
          site_list?.length == 1?  <h6 id={styles.site_header} className="h6 mt-1">There is {site_list?.length} Site registered on your number, Please Select
          an Site to process further.</h6>:<h6 id={styles.site_header} className="h6 mt-1">There are {site_list?.length} Sites registered on your number, Please Select
          an Site to process further.</h6>} 
          <div id={styles.filter_container}>
                  <form  onSubmit={handleSearch}>
                  {" "}
                  <input
                    type="text"
                    placeholder="Search here.."
                    value={search_term}
                    onChange={(e) => setSearch_Term(e.target.value)}
                    className="form-control"
                    // required
                  />
                                  <button
                  className="btn btn-primary"
                   type="submit"
                >
                  search
                </button>
                </form>
                </div>
          {site_list?.length == 0?<></>  : <div  id={styles.site_list_container} >
              <div className="col-md-6">
                <div className="col-md-12" style={{maxHeight:"54vh",overflow:"auto"}}>
               {search_term?.length>0?<></>: <div
                        id={styles.name_contain}
                      >
                        <h2
                          style={{display:"flex", justifyContent:"space-between", gap:"2vw"}}
                          onClick={()=>handleClick("all")}
                        >
                          <span 
                          >
                            All Sites
                            </span>
                            <PlayArrowIcon/>
                            {/* <span style={{fontSize:"20px"}}>&#11208;</span> */}
                            </h2>                       
                      </div>}
                  {site_list?.map((ele, index) => (
                    <>
                     <div
                        id={styles.name_contain}
                      >
                        <h2
                          style={{display:"flex", justifyContent:"space-between", gap:"2vw"}}
                          onClick={()=>handleClick(ele)}
                        >
                          <span 
                          >
                            {ele?.site_name}
                            </span>
                            <PlayArrowIcon/>
                            {/* <span style={{fontSize:"20px"}}>&#11208;</span> */}
                            </h2>                       
                      </div>
                    </>
                  ))}
                        </div>
              </div>
            </div>}
          
            {/* {audit_site_list?.length == 0?<h6 id={styles.site_header} className="h6 mt-5">There is no Audit Site registered on your number</h6>
          :
          audit_site_list?.length == 1?  <h6 id={styles.site_header} className="h6 mt-5">There is {audit_site_list?.length} Audit Site registered on your number, Please Select
          an Site to process further.</h6>:<h6 id={styles.site_header} className="h6 mt-5">There are {audit_site_list?.length} Audit Sites registered on your number, Please Select
          an Site to process further.</h6>}  */}

          {/* {audit_site_list?.length == 0?<></>  : <div id={styles.site_list_container} >
              <div className="col-md-6">
                <div className="col-md-12" style={{maxHeight:"54vh",overflow:"auto"}}>
                  {audit_site_list?.map((ele, index) => (
                    <>
                     <div
                        id={styles.name_contain}
                      >
                        <h2
                          style={{display:"flex", justifyContent:"space-between", gap:"2vw"}}
                          onClick={()=>handleClick_Audits(ele)}
                        >
                          <span 
                          >
                            {ele?.site_name}
                            </span>
                            <PlayArrowIcon/>
                            </h2>                       
                      </div>
                    </>
                  ))}
                        </div>
              </div>
            </div>} */}
            
          </>
        )}
        
    </div>
       <Iris_footer/>
       </>
  )
}

export default User_site_list
