import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css"
import Drawer_customer from '../Components/Drawer';
import loader_img from "../Media/Business_chart.gif"
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from "moment";
import Footer from '../Components/footer';

function Purchase_order({base_url}) {

  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  let User_mode = storage.getItem("User_mode");
  let iris_logo_image = storage.getItem("iris_logo_image");
  let master_iris_client_id = storage.getItem("master_iris_client_id");

  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const [open, setOpen] = React.useState(false);
  const [purchase_data,setPurchase_Data] = useState([])
  const [purchase_load,setPurchase_Load] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


const get_purchase_order = async()=>{
  setPurchase_Load(true);
  let token = storage.getItem("token");
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token?.replace('"', ""),
  };
  const formData = new FormData();

  formData.append("customer_id", customer_id);
  if (site_id != "" && site_id != null) {
    formData.append("site_id", site_id);
  }
  axios({
    url: `${base_url}/purchase-order-list`,
    method: "POST",
    data: formData,
  })
    .then((res) => {
      setPurchase_Load(false);
      if (res?.data?.code == "200") {
        setPurchase_Data(res?.data?.data)
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: res?.data?.message,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      }
    })
    .catch((err) => {
      setPurchase_Load(false);
    });
}

  useEffect(()=>{
    if(token != "" && token != null){
      if(User_mode == "customer"){
        storage.setItem("site_id","")
        }
      get_purchase_order();
    }

  },[])

    const columns = [
        {
          name: "purchase_order_no",
          label: "Purchase Order No",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "po_amount",
          label: "Total PO Amount",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (row) => {
              return <p>{row == null?0:new Intl.NumberFormat("en-IN").format(row)}</p>;
            },
          },
        },
        {
          name: "billed_amount",
          label: "Total Billed Amount",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row) => {
              return <p>{row == null?0:new Intl.NumberFormat("en-IN").format(row)}</p>;
            },
          }
        },
        {
          name: "unbilled_amount",
          label: "Total Unbilled Amount",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row) => {
              return <p>{row == null?0:new Intl.NumberFormat("en-IN").format(row)}</p>;
            },
          },
        },
        {
          name: "po_startdate",
          label: "PO Start Date",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
        {
          name: "po_Enddate",
          label: "PO End Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
      
      ];
      
      const options = {
        filterType: "checkbox",
      };
      
  return (
    <>
    {/* <MenuAppBar/> */}
    {/* <ListIcon sx={{marginLeft:"-95%"}} onClick={toggleDrawer(true)}/> */}
    {/* <SuperAdmin/> */}
    <Drawer_customer/>
  {purchase_load?<img src={loader_img} id={styles.loader} />:  <div className='container-fluid' style={{paddingLeft:"5em",marginTop:"-2rem"}}>
  {master_iris_client_id == "a95226c4-9917-4db8-9009-0481155bae76"?<img src={iris_logo_image} alt="text" id={styles.iris_logo_img} />:<></>}
         <div className='row' id={styles.purchase_header}>
            <div>
            <span>{purchase_data?.length}</span>
            <p>Total Purchase Orders</p>
            </div>
         </div>
         <div className='row mt-2'>
         <MUIDataTable
        // title={
        //   <h4 id={styles.list_head}>
        //     Purchase Orders 
        //   </h4>
        // }
        data={purchase_data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: false,
          filter:false,
          sortOrder: {
            name: "po_startdate",
            direction: "desc",
           },
          rowsPerPageOptions:[10,25,50,100],
          tableBodyMaxHeight: "57vh",
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
        }}
      />
         </div>
    </div>}

    {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
            
                    <p id={styles.copyright}>
               

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
  
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}

<Footer/>

    </>
  )
}

export default Purchase_order