import React, { useRef, useState } from "react";
import styles from "../Styles/LoginStyle.module.css";
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import Service_details from "../Modals/Service_details";
import Swal from "sweetalert2";
import axios from "axios";

function ServicesPlans({ service_data,base_url }) {
  const unitRef = useRef();
  const storage = window.sessionStorage;
  let customer_id = storage.getItem("customer_id")
  const navigate = useNavigate();
  
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hover_data,setHover_Data] = useState([])
  const [service_details,setService_Details] = useState([])
  const [details_load,setDetails_Load] = useState(false)

  function handleClick(event,ele) {
    setHover_Data(ele?.services_name)
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }



  const handleCheck = (ele, val) => {
    let arr = ele?.months?.split(",");

    for (let i = 0; i < arr?.length; i++) {
      if (arr[i] == val) {
        return true;
      }
    }
  };

  const handleNavigate = (ele) => {
    storage.setItem("site_id", ele?.site_id);
    storage.setItem("site_name", ele?.site_name);
    navigate("/site_page");
  };

  const [open, setOpen] = useState(false);

  const handleClosed = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetWidth,
        bottom: menu.offsetTop + menu.offsetHeight
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setOpen(false);
  };


const get_service_details = async (ele,val)=>{
setDetails_Load(true)
  let token = storage.getItem("token");
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token?.replace('"', ""),
  };
  const formData = new FormData();

  formData.append("customer_id", customer_id);
  formData.append("scheduling_id", ele?.scheduling_id);
  formData.append("year", ele?.year);
  formData.append("month", val);
  axios({
    url: `${base_url}/customer-scheduled-service-count-heatmap`,
    method: "POST",
    data: formData,
  })
    .then((res) => {
      setDetails_Load(false)
      if (res?.data?.code == "200") {
        setService_Details(res?.data?.data);
        handleOpen2();
      } else {
        handleClose2();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: res?.data?.message,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      }
    })
    .catch((err) => {
      handleClose2();
      setDetails_Load(false)
      
    });
}


const handleDetails =(ele,val)=>{
  get_service_details(ele,val)
  handleOpen2();
}

const handleTemp = ()=>{

}

  return (
    <div className="container-fluid">
      {service_data?.map((ele, index) => (
        <div className="row mt-2">
          <div className={`${styles.serviceName} col-3`}>
            <p
              style={{ textAlign: "left", cursor: "pointer" }}
              className="mt-2"
              onClick={() => handleNavigate(ele)}
            >
              {ele?.site_name}
            </p>
          </div>
          <div className="col-9">
            <div className="row">
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jan</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 1) ? "#007cc3" : "white", cursor:handleCheck(ele, 1) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 1) ?()=>handleDetails(ele,1):handleTemp}
                  // onMouseOver={handleCheck(ele, 1) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit1}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Feb</span>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    backgroundColor: handleCheck(ele, 2) ? "#007cc3" : "white", cursor:handleCheck(ele, 2) ?"pointer":""
                  }}
                        onClick={handleCheck(ele, 2) ?()=>handleDetails(ele,2):handleTemp}
                  // onMouseOver={handleCheck(ele, 2) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit1}
                >

                </div>

              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Mar</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 3) ? "#007cc3" : "white", cursor:handleCheck(ele, 3) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 3) ?()=>handleDetails(ele,3):handleTemp}
                  // onMouseOver={handleCheck(ele, 3) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit2}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Apr</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 4) ? "#007cc3" : "white", cursor:handleCheck(ele, 4) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 4) ?()=>handleDetails(ele,4):handleTemp}
                  // onMouseOver={handleCheck(ele, 4) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>May</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 5) ? "#007cc3" : "white", cursor:handleCheck(ele, 5) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 5) ?()=>handleDetails(ele,5):handleTemp}
                  // onMouseOver={handleCheck(ele, 5) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit2}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jun</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 6) ? "#007cc3" : "white", cursor:handleCheck(ele, 6) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 6) ?()=>handleDetails(ele,6):handleTemp}
                  // onMouseOver={handleCheck(ele, 6) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit2}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Jul</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 7) ? "#007cc3" : "white", cursor:handleCheck(ele, 7) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 7) ?()=>handleDetails(ele,7):handleTemp}
                  // onMouseOver={handleCheck(ele, 7) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit1}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Aug</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 8) ? "#007cc3" : "white", cursor:handleCheck(ele, 8) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 8) ?()=>handleDetails(ele,8):handleTemp}
                  // onMouseOver={handleCheck(ele, 8) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit3}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Sep</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 9) ? "#007cc3" : "white", cursor:handleCheck(ele, 9) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 9) ?()=>handleDetails(ele,9):handleTemp}
                  // onMouseOver={handleCheck(ele, 9) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit1}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Oct</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 10) ? "#007cc3" : "white", cursor:handleCheck(ele, 10) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 10) ?()=>handleDetails(ele,10):handleTemp}
                  // onMouseOver={handleCheck(ele, 10) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit3}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Nov</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 11) ? "#007cc3" : "white", cursor:handleCheck(ele, 11) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 11) ?()=>handleDetails(ele,11):handleTemp}
                  // onMouseOver={handleCheck(ele, 11) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit3}
                ></div>
              </div>
              <div className={`${styles.unitDiv} col-1`}>
                {index == 0 ? (
                  <span className={styles.monthTitle}>Dec</span>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    backgroundColor: handleCheck(ele, 12) ? "#007cc3" : "white", cursor:handleCheck(ele, 12) ?"pointer":""
                  }}
                  onClick={handleCheck(ele, 12) ?()=>handleDetails(ele,12):handleTemp}
                  // onMouseOver={handleCheck(ele, 12) ? (e)=>handleClick(e,ele) : handleTemp}
                  // aria-owns={anchorEl ? "simple-menu" : undefined}
                  // aria-haspopup="true"
                  className={styles.unit1}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        className={styles.menu_container}
      >
      {hover_data?.map((ele)=>(
      <MenuItem sx={{overflow:"auto",borderBottom:"1px solid #adb5bd"}} onClick={handleClose}>
      {ele?.testing_service_name} <span style={{ marginLeft: "40%" }}>{ele?.collection_count}</span>
    </MenuItem>
      ))}  
      </Menu> 

<Service_details
open={open2}
handleOpen={handleOpen2}
handleClose={handleClose2}
data={service_details}
details_load={details_load}
/>


      {/* <div class="dropdown">
  <span>Mouse over me</span>
  <div class="dropdown-content">
  <p>Hello World!</p>
  </div>
</div> */}

      {/* <div className="row mt-2">
        <div className={`${styles.serviceName} col-3`}>
          <p>Food</p>
        </div>
        <div className="col-9">
          <div className="row">
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jan</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Feb</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Mar</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Apr</span>
              <div className={styles.unit}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jun</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jul</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Aug</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Sep</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Oct</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Nov</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Dec</span>
              <div className={styles.unit1}></div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="row mt-2">
        <div className={`${styles.serviceName} col-3`}>
          <p>Food</p>
        </div>
        <div className="col-9">
          <div className="row">
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jan</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Feb</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Mar</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Apr</span>
              <div className={styles.unit}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jun</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jul</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Aug</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Sep</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Oct</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Nov</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Dec</span>
              <div className={styles.unit1}></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ServicesPlans;

// import React from 'react'
// import HeatMap from '@uiw/react-heat-map';

// const value = [
//     { date: '2016/09', count:2 },
//     { date: '2016/04', count:2 },
//     ...[...Array(17)].map((_, idx) => ({ date: `2016/01/${idx + 10}`, count: idx })),
//     ...[...Array(17)].map((_, idx) => ({ date: `2016/02/${idx + 10}`, count: idx })),
//     { date: '2016/04/12', count:2 },
//     { date: '2016/05/01', count:5 },
//     { date: '2016/05/02', count:5 },
//     { date: '2016/05/03', count:1 },
//     { date: '2016/05/04', count:11 },
//     { date: '2016/05/08', count:32 },
//     { date: '2016/10/08', count:32 },
//     { date: '2016/11/08', count:32 },
//     { date: '2016/12/30', count:32 },
//   ];

// function ServicesPlans() {
//   return (
//     <div>
//         Heat map
//         <div>
//         <HeatMap
//             width={930}
//             value={value}
//             // weekLabels={['', 'Mon', '', 'Wed', '', 'Fri', '']}
//             startDate={new Date('2016/01')}
//             rectSize={15}
//             // monthLabels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
//         />
//         </div>
//     </div>
//   )
// }

// export default ServicesPlans;

{
  /* <div className={styles.legendDiv}>
        <div className={styles.legendUnit} style={{backgroundColor:"#b1d4e8"}}></div> <span>Planned</span>
        <div className={styles.legendUnit} style={{backgroundColor:"#56acdd"}}></div> <span>Scheduled</span>
        <div className={styles.legendUnit} style={{backgroundColor:"#007cc3"}}></div> <span>Collected</span>
      </div> */
}

{
  /* <div className="row mt-2">
        <div className={`${styles.serviceName} col-3`}>
          <p>Customer Hub Name</p>
        </div>
        <div className="col-9">
          <div className="row">
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jan</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Feb</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Mar</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Apr</span>
              <div className={styles.unit}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jun</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jul</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Aug</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Sep</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Oct</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Nov</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Dec</span>
              <div className={styles.unit1}></div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className={`${styles.serviceName} col-3`}>
          <p>Food</p>
        </div>
        <div className="col-9">
          <div className="row">
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jan</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Feb</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Mar</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Apr</span>
              <div className={styles.unit}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jun</span>
              <div className={styles.unit2}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Jul</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Aug</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Sep</span>
              <div className={styles.unit1}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Oct</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Nov</span>
              <div className={styles.unit3}></div>
            </div>
            <div className={`${styles.unitDiv} col-1`}>
              <span className={styles.monthTitle}>Dec</span>
              <div className={styles.unit1}></div>
            </div>
          </div>
        </div>
      </div> */
}
